import styled from 'styled-components';

export const DatePickerStyle = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  margin: 0 10px;
  h5 {
    margin: 0 8px;
    font-weight: 400;
  }
  > div > div > button {
    width: 100%;
  }
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 9px 12px;
    font-size: 14px;
    margin-left: 10px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 5px 0 0;
    > div > div > button {
      padding: 6px 10px;
      font-size: 13px;
      > svg {
        width: 17px;
      }
    }
    select {
      padding: 9px 5px;
    }
  }
`;
