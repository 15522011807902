import React, { useEffect, useMemo, useRef, useState } from 'react';
import CommonWrapper from '../CommonWrapper';
import TableController, { TableCol, TableInput, TableRow } from '../../common/TableController';
import FileSelector, { IMAGE_TYPE } from '../../input/FileSelector';
import Disclaimer from '../../common/Disclaimer';
import styled, { css } from 'styled-components';
import NotFoundContainer from '../../common/NotFoundContainer';
import { swalReturnWarning } from '../../../util/commonFunctions';
import { patchSeatCategory, postSeatCategory } from '../../../lib/api/v2/seat';
import { swalMessage } from '../../common';
import useHandleAPICall from '../../../lib/hooks/useHandleAPICall';
import useHandleError from '../../../lib/hooks/useHandleError';

const EditSeatCategory = ({
  seatCategory,
  onSuccess,
}: {
  seatCategory: any;
  onSuccess: () => void;
}) => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const fileRef = useRef<HTMLInputElement>(null);
  const [title, setTitle] = useState(seatCategory.seatCategoryName);
  const [currentFileName, setCurrentFileName] = useState(seatCategory.seatImage);
  const [encodedFile, setEncodedFile] = useState<string | undefined>(seatCategory.seatImage);

  const getEncodedFile = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error('FileReader result is null'));
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async () => {
    try {
      const currentFile = fileRef.current?.files as FileList;
      if (currentFile.length > 0) {
        const encoded = await getEncodedFile(currentFile[0]);
        return setEncodedFile(encoded);
      }
      if (currentFileName) return;
      return setEncodedFile(undefined);
    } catch (error) {
      setEncodedFile(undefined);
    }
  };

  const resetImagePreview = () => setEncodedFile(undefined);

  const checkBeforeUpload = () => {
    try {
      const throwError = (message: string) => {
        swalReturnWarning(message);
        throw new Error();
      };
      if (!currentFileName) throwError('이미지를 선택해 주세요.');
      if (!title) throwError('제목을 입력해 주세요.');
      if (title.length > 10) throwError('제목을 최대 10글자로 입력해 주세요.');
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleConfirm = async () => {
    try {
      const checkSucceed = checkBeforeUpload();
      if (!checkSucceed) return 'abort';
      const form = new FormData();
      form.append('seatCategoryName', title);
      if (fileRef.current?.files && fileRef.current?.files[0])
        form.append('seatCategoryImage', fileRef.current?.files[0]);
      form.append('seatCategoryId', seatCategory.id);
      await createRequest(patchSeatCategory, form);
      swalMessage('success', '카테고리를 수정했어요.', '확인', false);
      onSuccess();
    } catch (error) {
      catchError(error);
      return 'abort';
    }
  };

  useEffect(() => {
    handleFileChange();
  }, [fileRef.current?.files]);

  useEffect(() => {
    if (!currentFileName) resetImagePreview();
  }, [currentFileName]);

  return (
    <CommonWrapper title="카테고리 수정" confirmBtnText="수정" onConfirm={handleConfirm}>
      <TableController>
        <TableRow isStart>
          <TableCol title="제목">
            <TableInput
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="10자 이하로 입력하세요."
            />
          </TableCol>
        </TableRow>
        <TableRow>
          <TableCol title="이미지">
            <FileSelectorVerticalWrapper>
              <FileSelector
                fileRef={fileRef}
                currentFile={currentFileName}
                setCurrentFile={setCurrentFileName}
                fileType={IMAGE_TYPE}
              />
              <Disclaimer
                type="misc"
                dense
                message="10MB 이하, 1개의 파일만 등록 (jpg, png 지원)"
              />
              <Disclaimer type="misc" dense message="1:2 비율로 편집되어 업로드돼요." />
            </FileSelectorVerticalWrapper>
          </TableCol>
        </TableRow>
      </TableController>
      <SubTitle>사진 미리보기</SubTitle>
      <PhotoPreview backgroundImage={encodedFile}>
        {!encodedFile && (
          <NotFoundContainer text="등록된 사진이 없어요." marginTop={0} marginBottom={0} />
        )}
      </PhotoPreview>
    </CommonWrapper>
  );
};

export default EditSeatCategory;

const SubTitle = styled.div`
  font-size: 1rem;
  font-weight: bold;

  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const FileSelectorVerticalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const PhotoPreview = styled.div<{ backgroundImage?: string }>`
  height: 15rem;
  background: ${({ theme }) => theme.neutralGray[200]};
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background: url(${backgroundImage});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
    `}
`;
