import React, { useEffect, useState } from 'react';
import Receipt from '../components/receipt';
import { getReceipt } from '../lib/api';

import useHandleAPICall from '../lib/hooks/useHandleAPICall';
import useHandleError from '../lib/hooks/useHandleError';

const WebReceipt = () => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const getUrl = window.location.pathname;
  const transactionId = getUrl.replace('/receipt/', '');
  const [receiptData, setReceiptData] = useState({});

  const getReceiptData = async () => {
    try {
      const { data } = await createRequest(getReceipt, transactionId);

      setReceiptData(data);
    } catch (error) {
      catchError(error);
    }
  };

  useEffect(() => {
    getReceiptData();
  }, []);
  return <Receipt receiptData={receiptData} />;
};

export default WebReceipt;
