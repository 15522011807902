import React, { useEffect, useMemo, useRef, useState } from 'react';

import SimplePeer from 'simple-peer';
import { useParams } from 'react-router-dom';
import { swalMessage } from '../components/common';
import { socket } from '../lib/socket';
import PageContainer from '../components/common/PageContainer';
import styled from 'styled-components';

import IconCallReceive from '../assets/icon/call/icon_call_receive.svg';
import IconCallClose from '../assets/icon/call/icon_call_close.svg';
import { swalReturnWarning } from '../util/commonFunctions';
import Format from '../util/formatter';

import IconCallCameraUnauthorized from '../assets/icon/call/icon_call_camera_unauthorized.svg';

const WebCall = () => {
  const params: { uuid: string } = useParams();
  const callSocketId = params?.uuid;

  // 카메라 및 마이크 권한 부여 상태
  const [cameraPermission, setCameraPermission] = useState<string | null>(null);
  const [microphonePermission, setMicrophonePermission] = useState<string | null>(null);

  // video stream
  const [currentStream, setCurrentStream] = useState<MediaStream | null>(null);

  // call status
  const [receivingCall, setReceivingCall] = useState<boolean>(false);
  const [callAccepted, setCallAccepted] = useState<boolean>(false);
  const [callEnded, setCallEnded] = useState<boolean>(false);

  // socket data
  const [callerSignal, setCallerSignal] = useState<SimplePeer.SignalData | null>(null);
  const [name, setName] = useState<string>('');

  // references
  const myVideo = useRef<HTMLVideoElement | null>(null);
  const opponentVideo = useRef<HTMLVideoElement | null>(null);
  const connectionRef = useRef<SimplePeer.Instance | null>(null);

  // timers
  const elapsedTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  const elapsedTime = useRef<number>(0);

  const MyCamera = useMemo(() => {
    if (cameraPermission === 'granted') return <video playsInline muted ref={myVideo} autoPlay />;
    return (
      <CameraNotGranted>
        <img src={IconCallCameraUnauthorized} alt="camera-not-available" />
        VOICE ONLY
      </CameraNotGranted>
    );
  }, [cameraPermission]);

  const checkPermissions = async () => {
    try {
      // 카메라 권한 확인
      const cameraStatus = await navigator.permissions.query({ name: 'camera' as PermissionName });
      setCameraPermission(cameraStatus.state);
      // 마이크 권한 확인
      const microphoneStatus = await navigator.permissions.query({
        name: 'microphone' as PermissionName,
      });
      setMicrophonePermission(microphoneStatus.state);
      // 권한 상태 변경 감지
      cameraStatus.onchange = () => setCameraPermission(cameraStatus.state);
      microphoneStatus.onchange = () => setMicrophonePermission(microphoneStatus.state);

      return {
        camera: cameraStatus.state,
        microphone: microphoneStatus.state,
      };
    } catch (error) {
      return {
        camera: null,
        microphone: null,
      };
    }
  };

  const joinToRoom = () => {
    socket.emit(`joinCallRoom`, callSocketId);
  };

  const onCallAccepted = (signal: SimplePeer.SignalData) => {
    setCallAccepted(true);
    connectionRef.current?.signal(signal); // 연결된 Peer 인스턴스에 시그널 전달
  };
  const onCallRequestReceived = (data: {
    signal: SimplePeer.SignalData;
    from: string;
    name: string;
  }) => {
    setReceivingCall(true);
    setName(data.name);
    setCallerSignal(data.signal);
    answerCall();
  };

  const answerCall = () => {
    if (!currentStream || !callerSignal) return;
    setCallAccepted(true);
    const peer = new SimplePeer({
      initiator: false,
      trickle: false,
      stream: currentStream,
    });
    peer.on('signal', (data: SimplePeer.SignalData) => {
      socket.emit('answerCall', { signal: data, callRoomId: callSocketId });
    });
    peer.on('stream', (userStream: MediaStream) => {
      if (opponentVideo.current) {
        opponentVideo.current.srcObject = userStream;
      }
    });
    peer.on('close', () => {
      onConnectionClose();
    });
    peer.on('error', (_) => {
      onConnectionHangUp();
    });
    peer.signal(callerSignal);
    connectionRef.current = peer;
  };

  const reload = () => window.location.reload();

  const onConnectionClose = () => {
    leaveCall();
    swalMessage('success', '통화를 종료했습니다.', '확인', false, reload, reload);
  };

  const onConnectionHangUp = () => {
    leaveCall();
    swalMessage('warning', '키오스크에서 통화를 종료했습니다.', '확인', false, reload, reload);
  };

  const leaveCall = () => {
    setCallEnded(true);
    if (!connectionRef.current) return;
    connectionRef.current?.destroy();
    connectionRef.current = null;
  };

  const prepareSession = async () => {
    joinToRoom();
    const permission = await checkPermissions();
    /**
     * 카메라는 허용하지 않아도 가능하나, 마이크는 반드시 허용이 필요하도록 설정
     * 마이크 권한이 거부되었을 경우 권한 허용을 해 달라는 모달을 표시
     */
    if (permission.microphone === 'denied')
      return swalReturnWarning('마이크 권한을 허용 후 새로고침해 주세요.');
    if (!navigator || !navigator.mediaDevices) {
      return swalReturnWarning(
        '웹으로의 통화를 위한 기능(WebRTC)이 지원되지 않는 기기이거나, 마이크 및 카메라 권한을 허용하지 않았습니다.',
      );
    }
    if (permission.microphone === 'granted' && permission.camera === 'granted') {
      navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
        setCurrentStream(stream);
        if (myVideo.current) myVideo.current.srcObject = stream;
      });
      return;
    }
    if (permission.microphone === 'granted' && permission.camera === 'denied') {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        setCurrentStream(stream);
        if (myVideo.current) myVideo.current.srcObject = stream;
      });
      return;
    }
  };

  useEffect(() => {
    if (callAccepted && !callEnded) {
      const intervalId = setInterval(() => {
        elapsedTime.current += 1;
        setElapsedSeconds(elapsedTime.current); // 상태 업데이트
      }, 1000);
      elapsedTimerRef.current = intervalId;
      return () => {
        clearInterval(intervalId); // 컴포넌트 언마운트 시 클리어
      };
    }
  }, [callAccepted, callEnded]);

  useEffect(() => {
    prepareSession();
    socket.on('callUser', onCallRequestReceived);
    socket.on('callAccepted', onCallAccepted);
    return () => {
      socket.off('callUser');
      socket.off('callAccepted');
    };
  }, []);

  return (
    <PageContainer title="키오스크와 통화">
      <Videos>
        {MyCamera}
        {callAccepted && <video playsInline ref={opponentVideo} autoPlay />}
      </Videos>
      <Content>
        {!receivingCall && <h1>통화 요청을 기다리고 있습니다...</h1>}
        {receivingCall && !callAccepted ? (
          <>
            <h1>{name} 키오스크</h1>
            <CallReceiveButton onClick={answerCall}>
              <img src={IconCallReceive} alt="receive-call" />
              전화 받기
            </CallReceiveButton>
          </>
        ) : (
          <></>
        )}
        {callAccepted && !callEnded && (
          <>
            <h1>{name} 키오스크</h1>
            <h5>통화 중... ({Format.formatSecondsToMMss(elapsedSeconds)})</h5>
            <CallCloseButton onClick={leaveCall}>
              <img src={IconCallClose} alt="close-call" />
              통화 종료
            </CallCloseButton>
          </>
        )}
      </Content>
    </PageContainer>
  );
};

export default WebCall;

const CameraNotGranted = styled.div`
  width: 100%;
  min-height: 16rem;

  background: ${({ theme }) => theme.neutralGray[400]};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  color: white;
  font-size: 2rem;
  font-weight: 600;

  img {
    width: 25%;
  }
`;

const Videos = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  video {
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const CallButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: white;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
  img {
    width: 2rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  word-break: keep-all;
`;

const CallReceiveButton = styled(CallButton)`
  background: #0db90a;
`;

const CallCloseButton = styled(CallButton)`
  background: #e20014;
`;
