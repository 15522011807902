import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';

import Swal from 'sweetalert2';
import Breadcrumbs from '../layout/breadcrumb';
import { EntryExitLog, merchantId_api, userList } from '../lib/api';
import DetailsModal from '../components/modal/detailsModal';

import { DatePickerStyle } from './seatInfo';
import { userExitColumns } from '../data/columns';
import PaginationComponent from '../components/pagination';

import { exportExcel } from '../components/common';
import { TabButton, TabList } from './seatList';
import PerPageSelect from '../components/table/PerPageSelect';
import Table, { TableHeaderContainerV2 } from '../components/common/Table';
import PageContainer from '../components/common/PageContainer';

import { ExcelButton } from '../components/common/Button';

import DatePickerComponent from '../components/filter/DatePicker';
import Search from '../components/common/Search';

import useHandleAPICall from '../lib/hooks/useHandleAPICall';
import useHandleError from '../lib/hooks/useHandleError';

const ExitLog = ({ setIsLoading }) => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [merchantId, setMerchantId] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [exitLog, setExitLog] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [defaultList, setDefaultList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [tabData, setTabData] = useState('All');

  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userData, setUserData] = useState({});

  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;

  const [searchOption, setSearchOption] = useState('좌석번호');

  const searchKeywordData = ['좌석번호', '입장권', '내역', '전화번호', '권번'];

  const getData = async (startDate, endDate) => {
    try {
      const { data } = await createRequest(merchantId_api);
      setMerchantId(data.id);
      getEntryExitLog(startDate, endDate);
    } catch (error) {
      catchError(error);
    }
  };

  const sendDetailData = (phoneNum) => getUserList(phoneNum);
  const getUserList = async (phoneNum) => {
    try {
      const result = await createRequest(userList, merchantId, phoneNum);
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    } catch (error) {
      catchError(error);
    }
  };

  const showDetailModal = () => setDetailUserModal(!detailUserModal);

  const getMerchantId = async () => {
    try {
      const { data } = await createRequest(merchantId_api);
      setMerchantId(data.id);
    } catch (error) {
      catchError(error);
    }
  };

  const getEntryExitLog = async (startDate, endDate) => {
    if (!merchantId) return;

    try {
      const term = moment(endDate).diff(moment(startDate), 'days');
      if (term > 31) return;
      const ExitLogData = await createRequest(
        EntryExitLog,
        merchantId,
        moment(startDate).format('YYYY-MM-DD 00:00:00'),
        moment(endDate).format('YYYY-MM-DD 23:59:59'),
      );
      const processData = ExitLogData.data.map((item) => ({
        ...item,
        idx: item.id,
        message1:
          item.message === '휴식종료(입장)' ? (
            <p style={{ color: '#3661E6' }}>외출종료(입장)</p>
          ) : item.message === '휴식' ? (
            <p style={{ color: '#E66036' }}>외출</p>
          ) : item.message.includes('입장') ? (
            <p style={{ color: '#3661E6' }}>{item.message}</p>
          ) : item.message.includes('퇴장') ? (
            <p style={{ color: '#E66036' }}>{item.message}</p>
          ) : item.message.includes('차감') ? (
            <p style={{ color: '#E66036' }}>{item.message}</p>
          ) : item.message.includes('병합') ? (
            <p style={{ color: '#3661E6' }}>{item.message}</p>
          ) : item.message.includes('자리이동') ? (
            <p style={{ color: '#1b431d' }}>{item.message.split('번')[0]}번에서 자리이동</p>
          ) : item.message.includes('사물함 만료') ? (
            <p style={{ color: '#3661E6' }}>{item.message}</p>
          ) : item.message.includes('사물함 사용') ? (
            <p style={{ color: '#E66036' }}>{item.message}</p>
          ) : (
            <p style={{ color: 'red' }}>비상출입</p>
          ),
        message2:
          item.message === '휴식종료(입장)'
            ? '외출종료(입장)'
            : item.message === '휴식'
            ? '외출'
            : item.message.includes('입장')
            ? item.message
            : item.message.includes('퇴장')
            ? item.message
            : item.message.includes('차감')
            ? item.message
            : item.message.includes('병합')
            ? item.message
            : item.message.includes('자리이동')
            ? '자리이동'
            : item.message.includes('사물함 만료')
            ? '사물함 만료'
            : item.message.includes('사물함 사용')
            ? '사물함 사용'
            : '비상출입',
        phoneNumTag:
          item.phoneNum == 0 ? null : !item.phoneNum && item.message.includes('비상출입') ? (
            `${item.message.split('-')[0]}`
          ) : (
            <div
              className="phoneNum-click"
              onClick={(e) => {
                sendDetailData(e.currentTarget.textContent);
              }}>
              {item.phoneNum}
            </div>
          ),

        seatNumber1: (item.seatNumber ? item.seatNumber : '-') + '번',
        create1: moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        typeData:
          item.type === 1
            ? '일회권'
            : item.type === 2
            ? '기간권'
            : item.type === 3
            ? '시간정액권'
            : item.type === 4
            ? '세미나실'
            : item.type === 5
            ? '사물함'
            : '',
        typeTag:
          item.type === 1 ? (
            <p style={{ color: '#EBAF22' }}>일회권</p>
          ) : item.type === 2 ? (
            <p style={{ color: '#E03D64' }}>기간권</p>
          ) : item.type === 3 ? (
            <p style={{ color: '#33946B' }}>시간정액권</p>
          ) : item.type === 4 ? (
            <p style={{ color: '#3E67B6' }}>세미나실</p>
          ) : item.type === 5 ? (
            <p style={{ color: '#AB84FF' }}>사물함</p>
          ) : (
            ''
          ),
      }));

      if (tabData === 'All') {
        setTotalCount(ExitLogData.data.length);
        setExitLog(processData);
        setDefaultList(processData);
        setCurrentPage(1);
      } else if (tabData === 'seat') {
        const ticketData = processData.filter((item) => item.type === 1);
        setTotalCount(ticketData.length);
        setExitLog(ticketData);
        setDefaultList(processData);
        setCurrentPage(1);
      } else if (tabData === 'locker') {
        const lockerData = processData.filter(
          (item) => item.type === 5 || item.subtype.includes('locker'),
        );
        setTotalCount(lockerData.length);
        setExitLog(lockerData);
        setDefaultList(processData);
        setCurrentPage(1);
      }
    } catch (error) {
      catchError(error);
    }
  };

  const handleSearchKeyword = (keyword) => {
    if (keyword === '') setSearchKeyword(null);
    else setSearchKeyword(keyword);
  };

  const searchList = (listData) => {
    let searchData;
    if (searchOption === '전화번호') {
      searchData = listData.filter((item) => item.phoneNum?.includes(searchKeyword));
    } else if (searchOption === '입장권') {
      searchData = listData.filter((item) => item.typeData?.toString().includes(searchKeyword));
    } else if (searchOption === '내역') {
      searchData = listData.filter((item) => item.message2?.toString().includes(searchKeyword));
    } else if (searchOption === '좌석번호') {
      if (searchKeyword.includes('번')) {
        searchData = listData.filter((item) => item.seatNumber1?.toString() === searchKeyword);
      } else {
        searchData = listData.filter(
          (item) => item.seatNumber1?.toString() === searchKeyword + '번',
        );
      }
    } else if (searchOption === '권번') {
      searchData = listData.filter((item) => item.ticketId?.toString().includes(searchKeyword));
    }

    return searchData;
  };

  const searchData = () => {
    if (!searchKeyword)
      return Swal.fire({ icon: 'error', title: '검색어를 입력해주세요', timer: 2000 });
    let copyData;
    if (tabData === 'All') {
      setExitLog(defaultList);
      copyData = [...defaultList];
    } else if (tabData === 'seat') {
      const ticketData = defaultList.filter((item) => item.type === 1);
      copyData = [...ticketData];
    } else if (tabData === 'locker') {
      const lockerData = defaultList.filter(
        (item) => item.type === 5 || item.subtype.includes('locker'),
      );
      copyData = [...lockerData];
      setExitLog(lockerData);
      setTotalCount(lockerData.length);
    }

    const searchDatas = searchList(copyData);
    setExitLog(searchDatas);
    setTotalCount(searchDatas.length);
    setCurrentPage(1);
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const changeShowData = (tabName) => {
    setIsLoading(true);
    setTabData(tabName);
    setCurrentPage(1);
    setSearchKeyword(null);
    if (tabName === 'All') {
      setExitLog(defaultList);
      setTotalCount(defaultList.length);
    } else if (tabName === 'seat') {
      const ticketData = defaultList.filter((item) => item.type === 1);
      setExitLog(ticketData);
      setTotalCount(ticketData.length);
    } else if (tabName === 'locker') {
      const lockerData = defaultList.filter(
        (item) => item.type === 5 || item.subtype.includes('locker'),
      );
      setExitLog(lockerData);
      setTotalCount(lockerData.length);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getMerchantId();
  }, []);

  useEffect(() => {
    if (!merchantId) return;
    getEntryExitLog(startDate, endDate);
  }, [merchantId]);

  return (
    <Fragment>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userData}
          back={true}
          // setData={getUserTicketListApi}
        />
      )}
      <Breadcrumbs parent="정보조회" title="지점 출입 정보 조회" />
      <PageContainer
        title="지점 출입 정보 조회"
        itemsRightSideTitle={[
          <ExcelButton
            onClick={() => exportExcel(exitLog, startDate, endDate, '지점 출입 정보 조회')}
          />,
        ]}>
        <TabList>
          <TabButton selected={tabData === 'All'} onClick={() => changeShowData('All')}>
            전체
          </TabButton>
          <TabButton selected={tabData === 'locker'} onClick={() => changeShowData('locker')}>
            사물함
          </TabButton>
          <TabButton selected={tabData === 'seat'} onClick={() => changeShowData('seat')}>
            일회권
          </TabButton>
        </TabList>
        <TableHeaderContainerV2>
          <DatePickerComponent
            withCalendar
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startMaxDate={endDate}
            endMinDate={startDate}
            maxPeriod={3}
            fetch={getEntryExitLog}
          />

          <PerPageSelect onChange={setPostsPerPage} />
          <div className="inner-grid-2">
            <DatePickerStyle>
              <select onChange={(e) => setSearchOption(e.target.value)} value={searchOption}>
                {searchKeywordData.map((item) => (
                  <option>{item}</option>
                ))}
              </select>
            </DatePickerStyle>

            <Search
              onSubmit={searchData}
              searchKeyword={searchKeyword}
              setSearchKeyword={handleSearchKeyword}
            />
          </div>
        </TableHeaderContainerV2>

        <Table
          data={currentPosts(exitLog)}
          columns={userExitColumns}
          striped={true}
          center={true}
        />

        {totalCount > 0 && (
          <PaginationComponent
            setCurrentPage={setCurrentPage}
            totalLength={totalCount}
            postsPerPage={postsPerPage}
            currentPage={currentPage}
          />
        )}
      </PageContainer>
    </Fragment>
  );
};

export default ExitLog;
