import React, { useEffect, useState } from 'react';
import { goingToExitSeatColumn } from '../../../data/columns';
import CommonWrapper from '../CommonWrapper';
import { getRemainTime } from '../../../lib/api/v2/seat';
import Table from '../../common/Table';
import useHandleAPICall from '../../../lib/hooks/useHandleAPICall';
import useHandleError from '../../../lib/hooks/useHandleError';

const GoingToExitSeatList = () => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [list, setList] = useState<any[]>([]);
  const fetch = async () => {
    try {
      const { data } = await createRequest(getRemainTime);
      setList([...data.map((seat: any) => ({ ...seat, level: `Lv.${seat.level}` }))]);
    } catch (error) {
      catchError(error);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <CommonWrapper title="퇴실 예정석" isConfirmButton={false} isCancelButton={false}>
      <div style={{ paddingBottom: '2rem' }}>
        <Table data={list} columns={goingToExitSeatColumn} />
      </div>
    </CommonWrapper>
  );
};

export default GoingToExitSeatList;
