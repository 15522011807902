import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { MENUITEMS, MENUITEMS_V2, UserMenuItems } from './menu';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getUserPayData } from '../../components/common';
import styled from 'styled-components';
import { merchantId_api } from '../../lib/api';
import 'dotenv/config';
import LogoLetmeup from '../../assets/images/Logo_letmeup.svg';
import ArrowRight1 from '../../assets/images/arrow_right.svg';
import Menu from '../../assets/images/menu.svg';
import { getAllToken } from '../../util/token';
import { useRecoilValue } from 'recoil';
import { merchantKioskVersionStore, userPhoneNumberState } from '../../state';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';
import useHandleError from '../../lib/hooks/useHandleError';

export const SideBar2 = ({ isShowSide, setIsShowSide, isMobile }) => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const history = useHistory();
  const [menuList, setMenuList] = useState([]);
  const location = useLocation();
  const userPhoneNumber = useRecoilValue(userPhoneNumberState);
  const merchantKioskVersion = useRecoilValue(merchantKioskVersionStore);

  const [showPay, setShowPay] = useState(true);

  const { adminToken, userToken, merchantToken } = getAllToken();

  const UserType = useMemo(() => {
    return {
      admin: !!adminToken && !merchantToken,
      adminMerchant: !!merchantToken && !!adminToken,
      merchantUserV1: !!merchantToken && !userToken && !!userPhoneNumber,
      merchantUserV2: !!merchantToken && !!userToken,
      userV2: !!userToken && !merchantToken,
      merchant: !!merchantToken && !userToken,
    };
  }, [userToken, merchantToken, adminToken, userPhoneNumber]);

  useEffect(() => {
    getCurrentMenuAndSaveMenuList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentMenuAndSaveMenuList = () => {
    const currentMenu = checkUserLevelAndGetMenu();
    setMenuList(currentMenu);
    setIsShowSide(false);
  };

  const checkUserLevelAndGetMenu = () => {
    // const level = localStorage.getItem('level');

    let result = [];
    if (UserType.admin) result = [];
    else if (UserType.userV2) result = [];
    else if (UserType.merchantUserV2 || UserType.merchantUserV1) result = UserMenuItems;
    else {
      // TODO: 키오스크 버전 체크 후 해당 메뉴 바 적용
      try {
        if (merchantKioskVersion > 1) result = MENUITEMS_V2;
        else result = MENUITEMS;
      } catch (error) {
        result = MENUITEMS;
      }
    }
    return JSON.parse(JSON.stringify(result));
  };

  const onClickExtendTopMenu = (type, menuListIndex, topMenuIndex) => {
    if (type === 'link') return getCurrentMenuAndSaveMenuList();
    const menuListCopy = [...menuList];
    const newArray = menuListCopy.map((item, index) =>
      index === menuListIndex
        ? {
            ...item,
            Items: [{ ...item.Items[0], active: true }],
          }
        : {
            ...item,
            Items: [{ ...item.Items[0], active: false }],
          },
    );

    // menuListCopy[menuListIndex].Items[topMenuIndex].active = !menuList[
    //   menuListIndex
    // ].Items[topMenuIndex].active;
    setMenuList(newArray);
  };

  const onClickSubMenu = (menuListIndex, topMenuIndex, subMenuIndex) => {
    const currentMenu = checkUserLevelAndGetMenu();
    setIsShowSide(false);
    currentMenu[menuListIndex].Items[topMenuIndex].active = true;
    currentMenu[menuListIndex].Items[topMenuIndex].children[subMenuIndex].active = true;
    setMenuList(currentMenu);
  };

  const moveToDashBoard = () => {
    const path = menuList[0].Items[0].path;
    setIsShowSide(false);
    getCurrentMenuAndSaveMenuList();
    return history.push(path);
  };

  const getShowPay = async () => {
    try {
      const { data } = await createRequest(merchantId_api);
      const result = await createRequest(getUserPayData, data.id);
      if (result.credit_store_id === null && result.simple_store_id === null) setShowPay(false);
    } catch (error) {
      catchError(error);
    }
  };

  useEffect(() => {
    const level = localStorage.getItem('level');
    if (level === 'merchant' && !userPhoneNumber) getShowPay();
  }, []);
  return (
    <SideBarContainer className={isShowSide ? 'active' : ''}>
      <div>
        {isMobile ? (
          <button onClick={() => setIsShowSide(false)}>
            <img src={Menu} alt="menu" />
          </button>
        ) : (
          <div />
        )}
        <div onClick={() => moveToDashBoard()} className="logo-wrapper">
          <img src={LogoLetmeup} alt="LogoLetmeup" />
        </div>
      </div>
      <ul>
        {menuList.map((topMenus, index) => (
          <TopMenus
            key={`${location.pathname}-${index}`}
            menuKey={`${location.pathname}-${index}`}
            pathname={location.pathname}
            menuItemIndex={index}
            onClickExtendTopMenu={onClickExtendTopMenu}
            topMenus={topMenus.Items}
            onClickSubMenu={onClickSubMenu}
            showPay={showPay}
          />
        ))}
      </ul>
    </SideBarContainer>
  );
};

const SideBarContainer = styled.div`
  position: fixed;
  z-index: 400;
  width: 14rem;
  height: 100%;
  background-color: #3c5169;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo-wrapper {
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 4rem;
    img {
      width: 8rem;
      /* width: 7.29vw; */
    }
  }
  > ul {
    overflow: auto;
    width: 100%;
    gap: 1rem;
  }
  > div {
    width: 100%;
    /* display: flex; */
    display: grid;
    grid-template-columns: 3rem auto 3rem;
    align-items: center;
    > img {
      width: 1.5rem;
      /* margin: 0 1rem; */
      /* width: 6.66vw;
        height: 6.66vw;
        margin: 0 5vw; */
    }
  }
  @media screen and (max-width: 1279px) {
    position: fixed;
    top: 0;
    /* width: 66.66vw; */
    /* margin-top: -15.55vw; */
    height: 100vh;

    transform: translateX(-14rem);
    transition: all 0.3s;
    &.active {
      transform: translateX(0);
    }

    /* .logo-wrapper {
      width: fit-content;
      height: 15.55vw;
      img {
        width: 27.77vw;
        height: 15.55vw;
      }
    } */
    > ul {
      overflow: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
    }
  }
`;

const TopMenus = ({
  menuKey,
  topMenus,
  menuItemIndex,
  onClickExtendTopMenu,
  onClickSubMenu,
  pathname,
  showPay,
}) => {
  useEffect(() => {}, [pathname]);
  return (
    <TopMenusContiner key={menuKey}>
      {topMenus.map((topMenu, index) => (
        <Fragment key={`${pathname}${index}`}>
          <TopMenuItem
            topMenu={topMenu}
            pathname={pathname}
            menuItemIndex={menuItemIndex}
            topItemIndex={index}
            onClickExtendTopMenu={onClickExtendTopMenu}
          />
          {topMenu.children && (
            <SubMenus
              pathname={pathname}
              menuItemIndex={menuItemIndex}
              topItemIndex={index}
              active={topMenu.active}
              subMuens={topMenu.children}
              onClickSubMenu={onClickSubMenu}
              showPay={showPay}
            />
          )}
        </Fragment>
      ))}
    </TopMenusContiner>
  );
};

const TopMenusContiner = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  cursor: pointer;
  :hover {
    background-color: #2f6d84;
  }
`;

const TopMenuItemContainer = styled.li`
  background-color: ${(props) => (props.active ? '#22899F' : 'none')};
  > a {
    padding: 0.75rem 1rem;
    display: grid;
    grid-template-columns: ${(props) => (props.userLevel === 'user' ? '82% 5%' : '1fr 82% 5%')};
    align-items: center;

    > img {
      width: 1rem;
      height: 1rem;
    }
    > span {
      font-size: 1rem;
      font-weight: 600;
      color: ${(props) => (props.active ? '#ffffff' : '#cfd8e2')};
    }
  }

  /* @media screen and (max-width: 805px) {
    > a {
      padding: 3.33vw 6.66vw;

      > img {
        width: 5vw;
        height: 5vw;
      }
      > span {
        font-size: 5vw;
      }
    }
  } */
`;

const TopMenuItem = ({ topMenu, pathname, menuItemIndex, topItemIndex, onClickExtendTopMenu }) => {
  const [isHover, setIsHover] = useState(false);
  const level = localStorage.getItem('level');
  const { userToken, merchantToken } = getAllToken();
  const userPhoneNumber = useRecoilValue(userPhoneNumberState);

  const UserType = useMemo(() => {
    return {
      merchantUserV1: !!merchantToken && !userToken && !!userPhoneNumber,
      merchantUserV2: !!merchantToken && !!userToken,
    };
  }, [userToken, merchantToken, userPhoneNumber]);

  return (
    <TopMenuItemContainer
      active={pathname.startsWith(topMenu.path) || topMenu.active}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => onClickExtendTopMenu(topMenu.type, menuItemIndex, topItemIndex)}
      userLevel={
        level === 'merchant' && (UserType.merchantUserV1 || UserType.merchantUserV2)
          ? 'user'
          : 'else'
      }>
      {topMenu.type === 'link' ? (
        <Link to={topMenu.path}>
          {!(UserType.merchantUserV1 || UserType.merchantUserV2) && topMenu.icon && (
            <img
              src={
                pathname.startsWith(topMenu.path) || topMenu.active
                  ? topMenu.icon.pressed
                  : isHover
                  ? topMenu.icon.hovered
                  : topMenu.icon.enabled
              }
            />
          )}

          <span>{topMenu.title}</span>

          {/* <img src={ArrowRight1} alt="arrow-right" /> */}
        </Link>
      ) : (
        <a>
          {!(UserType.merchantUserV1 || UserType.merchantUserV2) && topMenu.icon && (
            <img
              src={
                pathname.startsWith(topMenu.path) || topMenu.active
                  ? topMenu.icon.pressed
                  : isHover
                  ? topMenu.icon.hovered
                  : topMenu.icon.enabled
              }
            />
          )}
          <span>{topMenu.title}</span>
          <img src={ArrowRight1} alt="arrow-right" />
        </a>
      )}
    </TopMenuItemContainer>
  );
};

const SubMenus = ({
  subMuens,
  active,
  pathname,
  menuItemIndex,
  topItemIndex,
  onClickSubMenu,
  showPay,
}) => {
  return (
    <SubMenuContainer key={`${pathname}-${menuItemIndex}`} active={active}>
      {subMuens.map((subMenu, index) =>
        !showPay && subMenu?.path.includes('buy') ? (
          <></>
        ) : (
          <SubMenuItem
            key={`${pathname}-${subMenu.path}`}
            active={pathname.startsWith(subMenu.path)}
            onClick={() => onClickSubMenu(menuItemIndex, topItemIndex, index)}>
            <Link to={subMenu?.path}>
              <span></span>
              <span>{subMenu.title}</span>
              <span></span>
            </Link>
          </SubMenuItem>
        ),
      )}
    </SubMenuContainer>
  );
};

const SubMenuContainer = styled.ul`
  width: 100%;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: left;
  background-color: #2f6d84;
`;

const SubMenuItem = styled.li`
  background-color: ${(props) => (props.active ? '#22899F' : 'none')};
  > a {
    padding: 0.75rem 1rem;

    display: grid;
    grid-template-columns: 1fr 84%;
    align-items: center;
    > span {
      vertical-align: middle;
      color: ${(props) => (props.active ? '#ffffff' : '#cfd8e2')};
      font-size: 0.875rem;
      font-weight: 600;
    }
  }
  :hover {
    background-color: #297b91;
  }
  /* @media screen and (max-width: 805px) {
    > a {
      padding: 0.75rem 1rem;
      grid-template-columns: 1fr;
      > span {
        font-size: 1rem;
      }
    }
  } */
`;
