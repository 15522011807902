import React, { useEffect, useState } from 'react';
import PageContainer from '../../components/common/PageContainer';
import { Content, ContentArea, MessageSenderWriter } from '../../components/Message';
import { TableHeaderArea } from '../../components/common/Table';
import { Button } from '../../components/common/Button';
import { callerIdColumn } from '../../data/columns';
import useModal from '../../lib/hooks/useModal';
import Format from '../../util/formatter';
import { merchantId_api } from '../../lib/api';
import { swalMessage, swalSuccess } from '../../components/common';

import AddSendMessageTargetModal from '../../components/modal/message/AddSendMessageTarget';
import { commonWrapperOptions } from '../../components/modal/CommonWrapper';
import { swalReturnWarning } from '../../util/commonFunctions';
import NewTableComponent from '../../components/table/NewTableComponent';
import { postMessageEvery } from '../../lib/api/v2/message';
import { useHistory } from 'react-router-dom';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';
import useHandleError from '../../lib/hooks/useHandleError';

const MessageSendV2 = () => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const history = useHistory();
  const { openModal } = useModal();

  const [merchantId, setMerchantId] = useState(-1);
  const [selectedCallerIds, setSelectedCallerIds] = useState<any[]>([]);
  const [checkedItems, setCheckedItems] = useState<any[]>([]);

  const onUpdateCallerIds = (req: any[]) => {
    const duplicationRemoved = req.filter((item) => {
      return !selectedCallerIds.find((calledId) => item.phoneNum === calledId.phoneNum);
    });
    if (duplicationRemoved.length === 0)
      return swalReturnWarning(`수신 목록에 이미 추가된 전화번호입니다.`);
    if (req.length !== duplicationRemoved.length)
      swalReturnWarning(
        `중복된 ${req.length - duplicationRemoved.length}개 수신 번호는 추가되지 않았습니다.\n${
          duplicationRemoved.length
        }개 수신 번호가 추가되었습니다.`,
      );
    setSelectedCallerIds(
      [...selectedCallerIds, ...duplicationRemoved].map((item) => ({
        ...item,
        phoneNumberString: Format.formatPhone(item.phoneNum),
      })),
    );
    setCheckedItems([]);
  };

  const getMessagePoint = async () => {
    try {
      const { data } = await createRequest(merchantId_api);
      setMerchantId(data.id);
    } catch (error) {
      catchError(error);
    }
  };

  const handleClickOpenAddTargetModal = () =>
    openModal(
      () => <AddSendMessageTargetModal merchantId={merchantId} setSelected={onUpdateCallerIds} />,
      {
        ...commonWrapperOptions,
      },
    );
  const handleResetSelectedCallerIds = () => {
    if (selectedCallerIds.length < 1) return;
    swalMessage('warning', `전송 대상을 초기화하시겠습니까?`, '초기화', '취소', () => {
      setSelectedCallerIds([]);
      setCheckedItems([]);
      swalSuccess('초기화');
    });
  };
  const handleDeleteSelectedCallerIds = () => {
    if (checkedItems.length < 1) return;
    swalMessage(
      'warning',
      `선택된 ${checkedItems.length}개 수신 번호를 삭제하시겠습니까?`,
      '삭제',
      '취소',
      () => {
        const duplicationRemoved = selectedCallerIds.filter((item) => {
          return !checkedItems.find((calledId) => item.phoneNum === calledId.phoneNum);
        });
        setSelectedCallerIds(duplicationRemoved);
        setCheckedItems([]);
        swalSuccess('삭제');
      },
    );
  };

  const handleSend = async (
    image: File | null,
    message: string,
    messageTitle: string,
    messagePrefix?: string,
    messagePostfix?: string,
  ) => {
    if (!message) return swalMessage('warning', '메시지를 입력해 주세요.', '확인', false);
    try {
      const sendList = selectedCallerIds.map((callerId) => callerId.phoneNum);
      const form = new FormData();
      if (image) form.append('image', image);
      if (messageTitle) form.append('title', messageTitle);
      form.append('message', `${messagePrefix || ''}${message}${messagePostfix || ''}`);
      form.append('phoneNum', sendList.join(','));
      await createRequest(postMessageEvery, form);
      swalSuccess('메시지가 전송').then(() => {
        history.go(0);
      });
    } catch (error) {
      const { data } = error as any;
      const message = data || '전송에 실패했습니다.';
      swalMessage('error', message, '확인', false);
    }
  };

  useEffect(() => {
    getMessagePoint();
  }, []);

  return (
    <PageContainer title="메시지 전송">
      <ContentArea>
        <Content title="전송 대상">
          <TableHeaderArea
            summary={{
              mainSummaryPrefix: '선택 ',
              mainSummaryUnitString: '명',
              mainSummaryValue: checkedItems.length,
              subSummaryPrefix: '총 ',
              subSummaryUnitString: '명',
              subSummaryValue: selectedCallerIds.length,
            }}
            buttons={[
              <Button
                buttonType="action"
                disabled={checkedItems.length === 0}
                onClick={handleDeleteSelectedCallerIds}>
                선택 삭제
              </Button>,
              <Button buttonType="action" onClick={handleResetSelectedCallerIds}>
                초기화
              </Button>,
              <Button buttonType="action" onClick={handleClickOpenAddTargetModal}>
                대상 추가
              </Button>,
            ]}
          />
          <NewTableComponent
            data={selectedCallerIds}
            columns={callerIdColumn}
            defaultData={selectedCallerIds}
            isCheckbox
            checkboxConfig={{
              id: 'phoneNum',
              externalChecklist: checkedItems,
              setExternalChecklist: setCheckedItems,
            }}
          />
        </Content>
        <MessageSenderWriter callerIds={selectedCallerIds} handleSend={handleSend} />
      </ContentArea>
    </PageContainer>
  );
};

export default MessageSendV2;
