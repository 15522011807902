import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  p {
    margin: -1.5px 15px 0px 7px;
  }
`;
export const Checkbox = styled.label`
  input {
    display: none;
  }
  display: flex;

  // 체크박스 테두리
  .checkbox_icon {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: transparent;
    border: 2px solid #ccc;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
  }

  // 체크박스 가상요소
  .checkbox_icon::before,
  .checkbox_icon::after {
    content: '';
    display: inline-block;
    width: 2px;
    height: 0;
    background-color: #fff;
    position: absolute;
    transform-origin: left top;
  }

  // 가상요소 before
  .checkbox_icon::before {
    top: 7.2px;
    left: 0px;
    transform: rotate(-40deg);
  }

  // 가상요소 after
  .checkbox_icon::after {
    top: 12.6px;
    left: 5px;
    transform: rotate(-138deg);
  }

  // 체크되었을 때 테두리 설정
  input:checked + .checkbox_icon {
    border-color: #05c8da;
    background-color: #05c8da;
  }

  // 체크되었을 때 가상요소 before
  input:checked + .checkbox_icon::before {
    height: 6.8px; // 높이값 지정
    transition: all 0.1s ease; // 0.15초 변화시간 줌
  }

  // 체크되었을 때 가상요소 after
  input:checked + .checkbox_icon::after {
    height: 12.8px; // 높이값 지정
    transition: all 0.1s ease-in-out; // 0.15초 변화시간 + 딜레이 시간 줌
  }
`;
