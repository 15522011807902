import React, { Fragment, useEffect } from 'react';

import { merchantId_api, userBlock, userList, userTicketAccess } from '../lib/api';
import { useState } from 'react';
import moment from 'moment';
import { userListColumn } from '../data/columns';
import styled from 'styled-components';
import DetailsModal from '../components/modal/detailsModal';
import PaginationComponent from '../components/pagination';
import Swal from 'sweetalert2';
import { exportExcel } from '../components/common';
import { ToggleBtn } from './seatList';
import PerPageSelect from '../components/table/PerPageSelect';
import Table, { TableHeaderContainerV2 } from '../components/common/Table';
import PageContainer from '../components/common/PageContainer';
import { ExcelButton, ToggleButton } from '../components/common/Button';
import Search from '../components/common/Search';
import useHandleAPICall from '../lib/hooks/useHandleAPICall';
import useHandleError from '../lib/hooks/useHandleError';

const UserList = () => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [merchantId, setMerchantId] = useState(0);
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userPhoneNum, setUserPhoneNum] = useState();
  const [postsPerPage, setPostsPerPage] = useState(10);
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [isShowSuperEnter, setIsShowSuperEnter] = useState(false);
  const [isShowAccess, setIsShowAccess] = useState(false);

  const getMerchantId = async () => {
    try {
      const { data } = await createRequest(merchantId_api);
      getUserList(data.id);
      setMerchantId(data.id);
    } catch (error) {
      catchError(error);
    }
  };

  const changeAccessFunc = async (item, clickData) => {
    try {
      const result = await createRequest(
        userTicketAccess,
        clickData === 'super'
          ? {
              merchantId,
              superEnter: item.superEnter === 1 ? 0 : item.superEnter === 0 ? 1 : 2,
              userId: item.id,
              smsDeny: item.smsDeny,
              access: item.easyTicketAccessUser,
            }
          : {
              merchantId,
              superEnter: item.superEnter,
              userId: item.id,
              smsDeny: item.smsDeny,
              access:
                clickData === 'access' ? !item.easyTicketAccessUser : item.easyTicketAccessUser,
            },
      );
      if (result.status === 200) getUserList(merchantId);
    } catch (error) {
      catchError(error);
    }
  };

  const getUserList = async () => {
    try {
      const result = await createRequest(userList, merchantId, 'all');
      const changeData = result.data.reverse().map((item) => ({
        ...item,
        create: moment(item.createdAt).format('YYYY-MM-DD'),
        finger: item.fingerPrintNum ? 'O' : '-',
        kiosk: item.pinNum ? 'O' : '-',
        backoffice: item.password ? 'O' : '-',
        superEnterTag: (
          <ToggleBtn>
            <input
              type="checkbox"
              value={item.superEnter}
              checked={item.superEnter === 1}
              disabled={item.superEnter === 2}
              onChange={() => changeAccessFunc(item, 'super')}
            />
            <span
              className={item.superEnter === 2 ? 'onoff-switch disabled' : 'onoff-switch'}></span>
          </ToggleBtn>
        ),
        access: (
          <ToggleBtn>
            <input
              type="checkbox"
              value={item.easyTicketAccessUser}
              checked={item.easyTicketAccessUser}
              onChange={() => changeAccessFunc(item, 'access')}
            />
            <span className="onoff-switch"></span>
          </ToggleBtn>
        ),
        block1: item.block ? (
          <button className="btn-success btn-sm" onClick={() => userblockFunc(item)}>
            차단해제
          </button>
        ) : (
          <button className="btn-secondary btn-sm" onClick={() => userblockFunc(item)}>
            차단
          </button>
        ),
        phoneNum2: (
          <div
            onClick={() => {
              sendDetailData(item);
            }}
            className="phoneNum-click">
            {item.phoneNum}
          </div>
        ),
        info: (
          <button className="btn-primary btn-sm" onClick={() => sendDetailData(item)}>
            자세히
          </button>
        ),
      }));

      if (isShowSuperEnter && isShowAccess) {
        const filterData = changeData.filter(
          (item) =>
            item.superEnter === 1 &&
            (item.easyTicketAccessUser === 1 || item.easyTicketAccessUser === true),
        );
        setUserData(filterData);
        setSearchData(filterData);
      } else if (isShowSuperEnter) {
        const filterData = changeData.filter((item) => item.superEnter === 1);
        setUserData(filterData);
        setSearchData(filterData);
      } else if (isShowAccess) {
        const filterData = changeData.filter(
          (item) => item.easyTicketAccessUser === 1 || item.easyTicketAccessUser === true,
        );
        setUserData(filterData);
        setSearchData(filterData);
      } else {
        setUserData(changeData);
        setSearchData(changeData);
      }
      if (searchKeyword && searchKeyword.length > 0) searchDataFun();
    } catch (error) {
      catchError(error);
    }
  };

  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const sendDetailData = (item) => {
    setDetailUserModal(true);
    setUserPhoneNum(item);
  };

  const handleSearchKeyword = (keyword) => {
    if (keyword === '') {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };

  const searchDataFun = () => {
    if (!userData) return;
    setCurrentPage(1);
    setSearchData([
      ...userData.filter((item) => {
        if (!searchKeyword) return true;
        return item.phoneNum && item.phoneNum.includes(searchKeyword);
      }),
    ]);
  };

  const userblockFunc = async (e) => {
    try {
      let blockData;
      if (e.block) {
        blockData = 0;
      } else {
        blockData = 1;
      }
      const result = await createRequest(userBlock, {
        merchantId: e.merchantId,
        userId: e.id,
        block: blockData,
      });
      if (result.status === 200 && blockData === 1)
        return Swal.fire({
          icon: 'success',
          title: '차단 설정이 완료되었습니다.',
          timer: 2000,
        }).then(() => getUserList());
      if (result.status === 200 && blockData === 0)
        return Swal.fire({
          icon: 'success',
          title: '차단 해제가 완료되었습니다.',
          timer: 2000,
        }).then(() => getUserList());

      Swal.fire({
        icon: 'error',
        title: '처리도중 에러가 발생했습니다. 다시 시도해주세요',
        timer: 2000,
      });
    } catch (error) {
      catchError(error);
    }
  };

  useEffect(() => {
    if (searchKeyword) searchDataFun();
  }, [userData]);

  useEffect(() => {
    if (!merchantId) {
      getMerchantId();
      return;
    }
    getUserList();
  }, [merchantId, isShowSuperEnter, isShowAccess]);
  return (
    <>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userPhoneNum}
          back={true}
          setData={getUserList}
          setSearchData={searchDataFun}
        />
      )}
      <Fragment>
        <PageContainer
          title="회원 정보 조회"
          itemsRightSideTitle={[
            <ExcelButton onClick={() => exportExcel(searchData, '', '', '회원 정보 조회')} />,
          ]}>
          <TableHeaderContainerV2>
            <div className="inner-grid-2">
              <ToggleButton
                enabled={isShowAccess}
                onClick={() => {
                  setIsShowAccess(!isShowAccess);
                  setCurrentPage(1);
                }}>
                QR/PIN 제한해제 {isShowAccess ? 'ON' : 'OFF'}
              </ToggleButton>
              <ToggleButton
                enabled={isShowSuperEnter}
                onClick={() => {
                  setIsShowSuperEnter(!isShowSuperEnter);
                  setCurrentPage(1);
                }}>
                슈퍼입장 {isShowSuperEnter ? 'ON' : 'OFF'}
              </ToggleButton>
            </div>
            <PerPageSelect onChange={setPostsPerPage} />
            <Search
              onSubmit={searchDataFun}
              searchKeyword={searchKeyword}
              setSearchKeyword={handleSearchKeyword}
            />
          </TableHeaderContainerV2>

          <Table
            data={currentPosts(searchData)}
            columns={userListColumn}
            striped={true}
            center={true}
          />
          {userData.length > 0 && (
            <PaginationComponent
              setCurrentPage={setCurrentPage}
              setPostsPerPageAdd={setPostsPerPageAdd}
              setPostsPerPageAdd1={setPostsPerPageAdd1}
              setPageNum={setPageNum}
              totalLength={searchData.length}
              postsPerPage={postsPerPage}
              currentPage={currentPage}
              pageNum={pageNum}
              postsPerPageAdd={postsPerPageAdd}
              postsPerPageAdd1={postsPerPageAdd1}
            />
          )}
        </PageContainer>
      </Fragment>
    </>
  );
};

export default UserList;
