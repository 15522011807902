import React, { Fragment, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Card, CardBody, Container, Form, Input } from 'reactstrap';
import { Button, ExcelButton } from '../components/common/Button';
import { ko } from 'date-fns/locale';
import { useState } from 'react';

import styled from 'styled-components';
import moment from 'moment';
import {
  getSeminarInfoList,
  getSeminarItemList,
  merchantId_api,
  ticketForceExit,
  ticketUsing,
  userTicketBlock,
  userList,
} from '../lib/api';
import DataTable from 'react-data-table-component';
import { seminarReserved } from '../data/columns';
import PaginationComponent from '../components/pagination';
import Swal from 'sweetalert2';
import { exportExcel } from '../components/common';
import { ExampleCustomInput, LabelRow } from './ticketCustom';
import { DatePickerStyle } from './seatInfo';
import SelectDateFuture from '../components/selectDateFuture';
import DetailsModal from '../components/modal/detailsModal';
import PerPageSelect from '../components/table/PerPageSelect';
import { FlexCenterV } from '../style/flexStyle';
import Table, { TableHeaderContainer, TableHeaderContainerV2 } from '../components/common/Table';
import PageContainer from '../components/common/PageContainer';
import { SearchButton } from '../components/input';
import DatePickerComponent from '../components/filter/DatePicker';
import Search from '../components/common/Search';
import useHandleAPICall from '../lib/hooks/useHandleAPICall';
import useHandleError from '../lib/hooks/useHandleError';
import Format from '../util/formatter';

const CardContainer = styled.div`
  .phoneNum-click {
    color: #04c9db;
    border-bottom: 1px solid #fff;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
`;

let mid;
const SeminarInfo = ({ setIsLoading }) => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [merchantId, setMerchantId] = useState(0);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(moment(new Date()).add(1, 'y')._d);
  const [reservationData, setReservationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;
  const [seminarData, setSeminarData] = useState([]);
  const [seminarId, setSeminarId] = useState();
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [defaultList, setDefaultList] = useState([]);

  //모달 state
  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userData, setUserData] = useState({});

  // 디테일 데이터 모달로 보내기
  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };
  // 휴대혼 번호 가져오기
  const getUserList = async (phoneNum) => {
    try {
      const result = await createRequest(userList, merchantId, phoneNum);
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    } catch (error) {
      catchError(error);
    }
  };

  //모달 on/off
  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  const getData = async () => {
    try {
      const { data } = await createRequest(merchantId_api);
      setMerchantId(data.id);
      getSeminarData(data.id);
      getSeminarList(data.id);
    } catch (error) {
      catchError(error);
    }
  };

  const getSeminarData = async (e) => {
    try {
      const { data } = await createRequest(getSeminarInfoList, e);
      setSeminarData(data);
      setSeminarId(data[0].id);
    } catch (error) {
      catchError(error);
    }
  };

  const getSeminarList = async (e, timedata) => {
    try {
      const data = await createRequest(
        getSeminarItemList,
        e,
        moment(startDate).format('YYYYMMDD000000'),
        moment(endDate).format('YYYYMMDD235959'),
      );
      const changeData = data.data.map((item) => ({
        ...item,
        start: moment(item.startDate).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(item.endDate).format('YYYY-MM-DD HH:mm:ss'),
        cancel: (
          <div className="btn btn-danger" onClick={() => reservationCancel(item)}>
            예약취소
          </div>
        ),
        phoneNum1: (
          <div
            className="phoneNum-click"
            onClick={() => {
              sendDetailData(item.phoneNum);
            }}>
            {Format.formatPhone(item.phoneNum)}
          </div>
        ),
      }));
      const defaultList = data.data.map((item) => ({
        ...item,
        start: moment(item.startDate).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(item.endDate).format('YYYY-MM-DD HH:mm:ss'),
        cancel: (
          <div className="btn btn-danger" onClick={() => reservationCancel(item)}>
            예약취소
          </div>
        ),
      }));

      setReservationData(changeData.filter((item) => +item.seminarId === +seminarId));
      setDefaultList(defaultList);
    } catch (error) {
      catchError(error);
    }
  };

  const reservationCancel = async (i) => {
    Swal.fire({
      title: '세미나실 예약을 취소하시겠습니까?',
      text: '예약취소 시 환불은 결제 정보 조회 - 결제 내역에서 별도로 수행하여야 합니다.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: '#04c9db',
      confirmButtonText: '예약취소',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const result = await createRequest(userTicketBlock, {
            ticketId: i.id,
            merchantId: i.merchantId,
            type: 4,
            block: !i.block,
          });

          if (result.status === 200) {
            const data = await createRequest(ticketUsing, i.userId, i.merchantId);

            if (data.status === 200 && data.data.length !== 0) {
              //퇴장처리
              const res = await createRequest(ticketForceExit, {
                merchantId: i.merchantId,
                id: i.id,
                userId: i.userId,
                seatId: data.data.seatId,
              });

              if (res.status === 200) {
                Swal.fire({
                  icon: 'success',
                  title: '예약이 취소되었습니다.',
                  timer: 2000,
                }).then(() => {
                  getData();
                });
              }
            } else {
              Swal.fire({
                icon: 'success',
                title: '예약이 취소되었습니다.',
                timer: 2000,
              }).then(() => {
                getData();
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: '예약 취소중 에러가 발생했습니다. 다시 시도해주세요',
              timer: 2000,
            }).then(() => {
              getData();
            });
          }
        } catch (error) {
          catchError(error);
        }
      }
    });
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const searchData = () => {
    if (!searchKeyword) {
      Swal.fire({ icon: 'error', title: '검색어를 입력해주세요', timer: 200 });
    } else {
      let copyData = [...defaultList];
      let searchData = copyData.filter(
        (item) =>
          item.phoneNum?.includes(searchKeyword) ||
          item.start?.includes(searchKeyword) ||
          item.end?.includes(searchKeyword),
      );

      setReservationData(searchData.filter((item) => +item.seminarId === +seminarId));
      setCurrentPage(1);
    }
  };

  const handleSearchKeyword = (keyword) => {
    if (keyword === '') {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (merchantId) {
      getSeminarList(merchantId);
    }
  }, [startDate, endDate, seminarId]);
  return (
    <>
      <Fragment>
        {detailUserModal && (
          <DetailsModal
            isOpen={detailUserModal}
            close={showDetailModal}
            seat={userData}
            back={true}
          />
        )}
        <PageContainer
          title="세미나실 예약확인"
          itemsRightSideTitle={[
            <ExcelButton
              onClick={() => exportExcel(reservationData, startDate, endDate, '세미나실 예약 현황')}
            />,
          ]}>
          <TableHeaderContainerV2>
            <DatePickerStyle>
              <select
                className="seminar_name"
                value={seminarId}
                onChange={(e) => setSeminarId(e.target.value)}>
                {seminarData.map((item, index) => (
                  <Fragment key={item.id}>
                    <option value={item.id} defaultChecked={(index = 0)}>
                      {item.name}
                    </option>
                  </Fragment>
                ))}
              </select>
            </DatePickerStyle>
            <DatePickerComponent
              withCalendar
              withFuture
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startMaxDate={endDate}
              endMinDate={startDate}
            />
            {/* <DatePicker
              selected={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              selectsStart
              locale={ko}
              startDate={startDate}
              maxDate={endDate}
              endDate={endDate}
              customInput={<ExampleCustomInput />}
            />
            <h5> - </h5>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              locale={ko}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              customInput={<ExampleCustomInput />}
            />
            <DatePickerStyle>
              <SelectDateFuture
                setStartDate={(val) => setStartDate(val)}
                setEndDate={(val) => setEndDate(val)}
              />
            </DatePickerStyle> */}

            <PerPageSelect onChange={setPostsPerPage} />
            <Search
              onSubmit={searchData}
              searchKeyword={searchKeyword}
              setSearchKeyword={handleSearchKeyword}
            />
            {/* <Form
              onSubmit={(e) => {
                e.preventDefault();
                searchData();
              }}>
              <LabelRow className="m-0">
                <Input
                  className="form-control"
                  style={{ margin: 0 }}
                  type="text"
                  placeholder="검색어를 입력해주세요"
                  defaultValue={searchKeyword}
                  onChange={(e) => handleSearchKeyword(e.target.value)}
                />

                <SearchButton onClick={searchData} />
              </LabelRow>
            </Form> */}
          </TableHeaderContainerV2>
          <CardContainer>
            <Table
              data={currentPosts(reservationData)}
              columns={seminarReserved}
              striped={true}
              center={true}
            />
          </CardContainer>

          {reservationData.length > 0 && (
            <PaginationComponent
              setCurrentPage={setCurrentPage}
              setPostsPerPageAdd={setPostsPerPageAdd}
              setPostsPerPageAdd1={setPostsPerPageAdd1}
              setPageNum={setPageNum}
              totalLength={
                // searchData.length >= 1
                //   ? searchData.length:
                reservationData.length
              }
              postsPerPage={postsPerPage}
              currentPage={currentPage}
              pageNum={pageNum}
              postsPerPageAdd={postsPerPageAdd}
              postsPerPageAdd1={postsPerPageAdd1}
            />
          )}
        </PageContainer>
      </Fragment>
    </>
  );
};

export default SeminarInfo;
