import React, { useState, useEffect } from 'react';
import { Fragment } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import Breadcrumb from '../layout/breadcrumb';
import { merchantId_api, getMerchantInfo, putMerchantSetting } from '../lib/api';
import { Label1 } from './lockerChange';
import { ToggleBtn } from './seatList';
import { ToggleContainer } from './setting';
import PageContainer from '../components/common/PageContainer';
import useHandleAPICall from '../lib/hooks/useHandleAPICall';
import useHandleError from '../lib/hooks/useHandleError';

const LabelBox = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelTime = styled(Label)`
  margin-right: 1vw;
  display: flex;
  width: 20%;
  > input {
    margin-right: 0.4vw;
    :disabled {
      background-color: #eee !important;
    }
  }
  align-items: center;
`;
const InputWithP = styled.div`
  display: flex;
  align-items: center;

  input {
    width: 20%;
    margin-right: 0.4vw;
  }
  p {
    padding: 0 10px;
  }
`;
export const Label2 = styled.label`
  font-weight: 500;
  display: flex;
  flex-direction: column;
  h5 {
    font-size: 14px;
    strong {
      color: red;
    }
  }
  p {
    margin-bottom: 0;
    font-size: 12px;
    color: #555;
  }
  :not(:last-child) {
    margin-bottom: 15px;
  }
  input,
  select {
    margin-top: 5px;
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 6px 13px;
  }

  @media screen and (max-width: 480px) {
    h5 {
      font-size: 13px;
    }
    input,
    select {
      padding: 9px 12px;
      font-size: 13px;
    }
  }
`;

const MerchantSetting = ({ setIsLoading }) => {
  const [merchantId, setMerchantId] = useState(0);
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  useEffect(() => {
    MerchantId();
  }, []);

  const [info, setInfo] = useState({
    min_price: '',
    seminarAmount: '',
    termTimeout: '',
    termTimeoutHour: '',
    termTimeoutMin: '',
    restTimeout: '',
    restTimeoutHour: '',
    restTimeoutMin: '',
    oneTimeSaleTimeCheck: '',
    oneTimeSaleStartTime: ':',
    oneTimeSaleEndTime: ':',
    kioskTimeCheck: '',
    kioskOpenTime: ':',
    kioskEndTime: ':',
    oneTimeLimit: '',
    termLimit: '',
    timeLimit: '',
    easyTicketAccessMerchant: '',
    usingKakaoAlarmTalk: '',
    salesMessageAlarm: '',
    salesMessagePhoneNum: '',
    overTime_sms_check: '',
    overTimeLimitPay: 0,
    timeTicketTimeLimit: '',
    timeTicketTimeLimitHour: '',
    timeTicketTimeLimitMin: '',
    pcRestartCheck: 1,
    pcRestartTime: '', // 키오스크 재시작 시간설정
    multiKioskFloor: '',
    overTimeCheck: 1,

    // 연령 제한 설정
    isAgeLimit: 0,
    limitAge: 0,
    limitAgeStartTime: '',
    limitAgeEndTime: '',
  });
  const [times, setTimes] = useState({
    oneTimeSaleStartH: '',
    oneTimeSaleStartM: '',
    oneTimeSaleEndH: '',
    oneTimeSaleEndM: '',
    kioskOpenTimeH: '',
    kioskOpenTimeM: '',
    kioskEndTimeH: '',
    kioskEndTimeM: '',
  });
  // const [main_image, setMainImg] = useState([]);

  const MerchantId = async () => {
    try {
      const { data } = await createRequest(merchantId_api);
      setMerchantId(data.id);
      const res = await createRequest(getMerchantInfo, data.id);
      const info = res.data[0];
      setInfo({
        min_price: info.min_price,
        seminarAmount: info.seminarAmount,
        oneTimeSaleTimeCheck: info.oneTimeSaleTimeCheck,
        oneTimeSaleStartTime: info.oneTimeSaleStartTime,
        oneTimeSaleEndTime: info.oneTimeSaleEndTime,
        termTimeout: info.termTimeout,
        termTimeoutHour: info.termTimeout / 3600 === 0 ? 0 : Math.floor(info.termTimeout / 3600),
        termTimeoutMin: (info.termTimeout % 3600) / 60 === 0 ? 0 : (info.termTimeout % 3600) / 60,
        restTimeout: info.restTimeout,
        restTimeoutHour: info.restTimeout / 3600 === 0 ? 0 : Math.floor(info.restTimeout / 3600),
        restTimeoutMin:
          (info.restTimeout % 3600) / 60 === 0 ? 0 : Math.floor((info.restTimeout % 3600) / 60),
        kioskOpenTime: info.kioskOpenTime,
        kioskEndTime: info.kioskEndTime,
        oneTimeLimit: info.oneTimeLimit,
        termLimit: info.termLimit,
        timeLimit: info.timeLimit,
        easyTicketAccessMerchant: info.easyTicketAccessMerchant,
        usingKakaoAlarmTalk: info.usingKakaoAlarmTalk,
        salesMessageAlarm: info.salesMessageAlarm,
        salesMessagePhoneNum: info.salesMessagePhoneNum,
        overTime_sms_check: info.overTime_sms_check,
        pcRestartTime: info?.pcRestartTime,
        pcRestartCheck: info?.pcRestartCheck,
        overTimeLimitPay: info?.overTimeLimitPay || 0,
        timeTicketTimeLimit: info?.timeTicketTimeLimit,
        timeTicketTimeLimitHour:
          info.timeTicketTimeLimit / 3600 === 0 ? 0 : Math.floor(info.timeTicketTimeLimit / 3600),
        timeTicketTimeLimitMin:
          (info.timeTicketTimeLimit % 3600) / 60 === 0
            ? 0
            : Math.floor((info.timeTicketTimeLimit % 3600) / 60),
        multiKioskFloor: info?.multiKioskFloor,
        overTimeCheck: info?.overTimeCheck,

        isAgeLimit: info?.isAgeLimit || 0,
        limitAge: info?.limitAge || 0,
        limitAgeStartTime: info?.limitAgeStartTime || '',
        limitAgeEndTime: info?.limitAgeEndTime || '',
      });
      setHour(info?.pcRestartTime.split(':')[0]);
      setMinute(info?.pcRestartTime.split(':')[1]);
      setTimes({
        oneTimeSaleStartH: info.oneTimeSaleStartTime ? info.oneTimeSaleStartTime.split(':')[0] : '',
        oneTimeSaleStartM: info.oneTimeSaleStartTime ? info.oneTimeSaleStartTime.split(':')[1] : '',
        oneTimeSaleEndH: info.oneTimeSaleEndTime ? info.oneTimeSaleEndTime.split(':')[0] : '',
        oneTimeSaleEndM: info.oneTimeSaleEndTime ? info.oneTimeSaleEndTime.split(':')[1] : '',
        kioskOpenTimeH: info.kioskOpenTime ? info.kioskOpenTime.split(':')[0] : '',
        kioskOpenTimeM: info.kioskOpenTime ? info.kioskOpenTime.split(':')[1] : '',
        kioskEndTimeH: info.kioskEndTime ? info.kioskEndTime.split(':')[0] : '',
        kioskEndTimeM: info.kioskEndTime ? info.kioskEndTime.split(':')[1] : '',
      });
    } catch (error) {
      catchError(error);
    }
  };

  const handleOnChange = (e) => {
    if (e.target.name === 'termTimeout' || e.target.name === 'restTimeout') {
      setInfo({ ...info, [e.target.name]: +e.target.value * 60 });
    } else if (e.target.name === 'expirationDate') {
      setInfo({
        ...info,
        [e.target.name]: +e.target.value > 12 ? 12 : +e.target.value,
      });
    } else {
      setInfo({ ...info, [e.target.name]: e.target.value });
    }
  };

  const handleTimeset = ({ target }) => {
    let valueH = target.value > 24 ? 24 : target.value;
    let valueM = target.value > 59 ? 59 : target.value;

    setTimes({
      ...times,
      [target.id]:
        target.id === 'oneTimeSaleStartH' || target.id === 'oneTimeSaleEndH' ? valueH : valueM,
    });
    setInfo({
      ...info,
      oneTimeSaleStartTime:
        oneTimeSaleStartH && oneTimeSaleStartM
          ? `${target.id === 'oneTimeSaleStartH' ? valueH : oneTimeSaleStartH}:${
              target.id === 'oneTimeSaleStartM' ? valueM : oneTimeSaleStartM
            }:00`
          : '',
      oneTimeSaleEndTime:
        oneTimeSaleEndH && oneTimeSaleEndM
          ? `${target.id === 'oneTimeSaleEndH' ? valueH : oneTimeSaleEndH}:${
              target.id === 'oneTimeSaleEndM' ? valueM : oneTimeSaleEndM
            }:00`
          : '',
    });
  };

  const handleSingleCheck = (checked, target) => {
    setInfo({ ...info, [target.name]: checked ? 1 : 0 });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let timeTicketTimeLimit =
      info.timeTicketTimeLimitHour * 3600 + info.timeTicketTimeLimitMin * 60;

    if (info.salesMessageAlarm && info.salesMessagePhoneNum.length !== 11)
      return Swal.fire({
        icon: 'error',
        title: '전화번호 11자리를 입력해주세요',
        timer: 2000,
      });
    if (timeTicketTimeLimit > 1800000)
      return Swal.fire({
        icon: 'error',
        title: '정액권 연장가능 시간은 500시간을 넘길 수 없습니다.',
        timer: 2000,
      });
    if (timeTicketTimeLimit < 60)
      return Swal.fire({
        icon: 'error',
        title: '정액권 연장가능 시간은 최소 1분은 설정하셔야합니다.',
        timer: 2000,
      });
    if (overTimeCheck === 1 && min_price === 0)
      return Swal.fire({
        icon: 'error',
        title: '초과결제를 사용하시려면 가격을 지정해주세요',
        timer: 2000,
      });
    if (isAgeLimit && (!limitAge || !limitAgeStartTime || !limitAgeEndTime))
      return Swal.fire({
        icon: 'error',
        title: '연령 제한과 제한 시간을 설정해 주세요.',
        timer: 2000,
      });

    const termTimes = info.termTimeoutHour * 3600 + info.termTimeoutMin * 60;
    const restTimes = info.restTimeoutHour * 3600 + info.restTimeoutMin * 60;

    try {
      const res = await createRequest(putMerchantSetting, {
        ...info,
        min_price: overTimeCheck === 0 ? 0 : min_price,
        termTimeout: termTimes,
        restTimeout: restTimes,
        timeTicketTimeLimit: timeTicketTimeLimit,
        merchantId,
      });
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: '수정이 완료됐습니다.',
          text: '외부키오스크 새로고침을 해주세요.',
          timer: 3000,
        }).then(() => {
          window.location.reload();
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: '수정에 실패했습니다. 다시 시도해주세요.',
          timer: 2000,
        });
      }
    } catch (error) {
      catchError(error);
    }
  };

  // const fileChange = (e) => {
  //   let main_tag = document.getElementsByClassName('main_image')[0];
  //   let top_tag = document.getElementsByClassName('top_image')[0];
  //   let logo_tag = document.getElementsByClassName('logo_image')[0];
  //   var reader = new FileReader();
  //   if (e.target.files[0].size < 10000000) {
  //     if (e.target.name === 'img_main') {
  //       main_tag.removeChild(main_tag.childNodes[0]);
  //       setMainImg({ ...main_image, mainfile: e.target.files[0] });
  //       reader.onload = function (event) {
  //         var img = document.createElement('img');
  //         img.setAttribute('src', event.target.result);
  //         main_tag.appendChild(img);
  //       };
  //     } else if (e.target.name === 'img_top') {
  //       top_tag.removeChild(top_tag.childNodes[0]);
  //       setMainImg({ ...main_image, topfile: e.target.files[0] });
  //       reader.onload = function (event) {
  //         var img = document.createElement('img');
  //         img.setAttribute('src', event.target.result);
  //         top_tag.appendChild(img);
  //       };
  //     } else {
  //       logo_tag.removeChild(logo_tag.childNodes[0]);
  //       setMainImg({ ...main_image, logofile: e.target.files[0] });
  //       reader.onload = function (event) {
  //         let img = document.createElement('img');
  //         img.setAttribute('src', event.target.result);
  //         logo_tag.appendChild(img);
  //       };
  //     }
  //   } else {
  //     Swal.fire(`첨부파일 사이즈는 10MB 이내로 등록 가능합니다.`);
  //     return;
  //   }
  //   reader.readAsDataURL(e.target.files[0]);
  // };

  const {
    min_price,
    oneTimeSaleTimeCheck,
    restTimeoutHour,
    restTimeoutMin,
    termTimeoutHour,
    termTimeoutMin,
    easyTicketAccessMerchant,
    usingKakaoAlarmTalk,
    salesMessageAlarm,
    salesMessagePhoneNum,
    overTime_sms_check,
    overTimeLimitPay,
    timeTicketTimeLimitHour,
    timeTicketTimeLimitMin,
    pcRestartCheck,
    overTimeCheck,
    isAgeLimit,
    limitAge,
    limitAgeStartTime,
    limitAgeEndTime,
  } = info;
  const { oneTimeSaleStartH, oneTimeSaleStartM, oneTimeSaleEndH, oneTimeSaleEndM } = times;
  const [hour, setHour] = useState('');
  const [minute, setMinute] = useState('');

  useEffect(() => {
    setInfo({ ...info, pcRestartTime: `${hour}:${minute}` });
  }, [hour, minute]);

  return (
    <Fragment>
      <Breadcrumb parent="하드웨어 및 설정" title="지점설정" />
      <PageContainer title="지점 설정">
        <FormContent>
          {overTimeCheck ? (
            <Label1>
              <h5>좌석 10분당 가격</h5>
              {/* <h5>좌석 초과 결제 금액(1분당)</h5> */}
              <InputWithP>
                <Input
                  style={{ width: '40%' }}
                  pattern="^[0-9]*$"
                  placeholder="숫자만"
                  type="text"
                  min="0"
                  name="min_price"
                  onChange={handleOnChange}
                  value={min_price || ''}
                />
                <p>원</p>
              </InputWithP>
            </Label1>
          ) : (
            <></>
          )}
          <ToggleContainer>
            <h5>초과결제 사용여부</h5>
            <ToggleBtn>
              <Input
                id="overTimeCheck"
                name="overTimeCheck"
                type="checkbox"
                checked={overTimeCheck}
                onChange={({ target }) => handleSingleCheck(target.checked, target)}
              />
              <span className="onoff-switch"></span>
            </ToggleBtn>
          </ToggleContainer>
          <ToggleContainer>
            <h5>일회권 판매제한 여부</h5>
            <ToggleBtn>
              <Input
                id="`oneTimeSaleTimeCheck`1"
                name="oneTimeSaleTimeCheck"
                type="checkbox"
                checked={oneTimeSaleTimeCheck || ''}
                onChange={({ target }) => handleSingleCheck(target.checked, target)}
              />
              <span className="onoff-switch"></span>
            </ToggleBtn>
          </ToggleContainer>

          <Label1>
            <h5>일회권 판매제한 시간 지정(00~24시간, 00~59분)</h5>
            <div>
              <LabelBox>
                <LabelTime htmlFor="oneTimeSaleStartH">
                  <Input
                    pattern="^[0-9]*$"
                    id="oneTimeSaleStartH"
                    type="text"
                    name="oneTimeSaleStartTime"
                    placeholder="시작(시간)"
                    value={oneTimeSaleStartH || ''}
                    onChange={handleTimeset}
                    disabled={!(oneTimeSaleTimeCheck === 1)}
                  />
                  시
                </LabelTime>
                <LabelTime htmlFor="oneTimeSaleStartM">
                  <Input
                    pattern="^[0-9]*$"
                    id="oneTimeSaleStartM"
                    type="text"
                    name="oneTimeSaleStartTime"
                    placeholder="시작(분)"
                    value={oneTimeSaleStartM || ''}
                    onChange={handleTimeset}
                    disabled={!(oneTimeSaleTimeCheck === 1)}
                  />
                  분
                </LabelTime>
              </LabelBox>
              <LabelBox>
                <LabelTime htmlFor="oneTimeSaleEndH">
                  <Input
                    pattern="^[0-9]*$"
                    id="oneTimeSaleEndH"
                    type="text"
                    name="oneTimeSaleEndTime"
                    placeholder="시작(시간)"
                    value={oneTimeSaleEndH || ''}
                    onChange={handleTimeset}
                    disabled={!oneTimeSaleTimeCheck}
                  />
                  시
                </LabelTime>
                <LabelTime htmlFor="oneTimeSaleEndM">
                  <Input
                    pattern="^[0-9]*$"
                    id="oneTimeSaleEndM"
                    type="text"
                    name="oneTimeSaleEndTime"
                    placeholder="시작(분)"
                    value={oneTimeSaleEndM || ''}
                    onChange={handleTimeset}
                    disabled={!oneTimeSaleTimeCheck}
                  />
                  분
                </LabelTime>
              </LabelBox>
            </div>
          </Label1>

          <Label1>
            <h5>기간권 최대 이용시간</h5>
            <InputWithP>
              <Input
                pattern="^[0-9]*$"
                type="text"
                min="0"
                name="termTimeoutHour"
                onChange={handleOnChange}
                value={termTimeoutHour || ''}
              />
              <p>시간</p>
              <Input
                pattern="^[0-9]*$"
                type="text"
                min="0"
                name="termTimeoutMin"
                onChange={handleOnChange}
                value={termTimeoutMin || ''}
              />
              <p>분</p>
            </InputWithP>
          </Label1>
          <Label1>
            <h5>최대 휴식시간</h5>

            <InputWithP>
              <Input
                pattern="^[0-9]*$"
                placeholder="숫자만"
                type="text"
                min="0"
                name="restTimeoutHour"
                onChange={handleOnChange}
                value={restTimeoutHour || ''}
              />
              <p>시간</p>
              <Input
                pattern="^[0-9]*$"
                type="text"
                min="0"
                name="restTimeoutMin"
                onChange={handleOnChange}
                value={restTimeoutMin || ''}
              />
              <p>분</p>
            </InputWithP>
          </Label1>
          {/* <Label1>
                    <h5>로고 이미지 변경</h5>
                    <InputWithP>
                      <Input
                        className="form-control"
                        type="file"
                        name="img_logo"
                        accept="image/*"
                        onChange={(e) => fileChange(e)}
                      />
                    </InputWithP>
                    <div className="logo_image">
                      <img src={main_image?.logofile} />
                    </div>
                  </Label1> */}
          <ToggleContainer>
            <h5>QR/Pin제한 여부</h5>
            <ToggleBtn>
              <Input
                id="easyTicketAccessMerchant"
                name="easyTicketAccessMerchant"
                type="checkbox"
                checked={easyTicketAccessMerchant || ''}
                onChange={({ target }) => handleSingleCheck(target.checked, target)}
              />
              <span className="onoff-switch"></span>
            </ToggleBtn>
          </ToggleContainer>
          <ToggleContainer>
            <h5>알림톡 사용여부</h5>
            <ToggleBtn>
              <Input
                id="usingKakaoAlarmTalk"
                name="usingKakaoAlarmTalk"
                type="checkbox"
                checked={usingKakaoAlarmTalk}
                onChange={({ target }) => handleSingleCheck(target.checked, target)}
              />
              <span className="onoff-switch"></span>
            </ToggleBtn>
          </ToggleContainer>
          <ToggleContainer>
            <h5>구매 메시지 알림 사용여부</h5>
            <ToggleBtn>
              <Input
                id="salesMessageAlarm"
                name="salesMessageAlarm"
                type="checkbox"
                checked={salesMessageAlarm}
                onChange={({ target }) => handleSingleCheck(target.checked, target)}
              />
              <span className="onoff-switch"></span>
            </ToggleBtn>
          </ToggleContainer>
          {salesMessageAlarm !== 0 && (
            <Label1>
              <h5>구매 메시지 알림 수신자번호</h5>
              <InputWithP>
                <Input
                  style={{ width: '100%' }}
                  pattern="^[0-9]*$"
                  type="text"
                  name="salesMessagePhoneNum"
                  onChange={handleOnChange}
                  value={salesMessagePhoneNum || ''}
                />
              </InputWithP>
            </Label1>
          )}
          <ToggleContainer>
            <h5>초과결제 해제 알림 문자 발송</h5>
            <ToggleBtn>
              <Input
                id="overTime_sms_check"
                name="overTime_sms_check"
                type="checkbox"
                checked={overTime_sms_check}
                onChange={({ target }) => handleSingleCheck(target.checked, target)}
              />
              <span className="onoff-switch"></span>
            </ToggleBtn>
          </ToggleContainer>
          <ToggleContainer>
            <h5>키오스크 재시작 시간 설정</h5>
            <ToggleBtn>
              <Input
                id="pcRestartCheck"
                name="pcRestartCheck"
                type="checkbox"
                checked={pcRestartCheck === 1}
                onChange={({ target }) => handleSingleCheck(target.checked, target)}
              />
              <span className="onoff-switch"></span>
            </ToggleBtn>
          </ToggleContainer>
          {pcRestartCheck === 1 && (
            <>
              <p style={{ marginBottom: '1vw' }}>
                ※ 시, 분 앞에 0을 붙여 두자리로 입력해야합니다. 예) 새벽 4시 &rarr; 04 00
              </p>
              <Label1>
                <InputWithP>
                  <Input
                    id="hour"
                    name="hour"
                    type="text"
                    placeholder="시간"
                    onChange={({ target }) => setHour(target.value)}
                    value={hour}
                  />
                  <p>시</p>
                  <Input
                    id="minute"
                    name="minute"
                    type="text"
                    placeholder="분"
                    onChange={({ target }) => setMinute(target.value)}
                    value={minute}
                  />
                  <p>분</p>
                </InputWithP>
              </Label1>
            </>
          )}
          <Label1>
            <h5>초과 결제 금액 상한선 설정</h5>
            <p>*설정을 원하시지 않을경우 0을 입력해주세요</p>

            <InputWithP>
              <Input
                style={{ width: '40%' }}
                pattern="^[0-9]*$"
                placeholder="숫자만"
                type="text"
                min="0"
                name="overTimeLimitPay"
                onChange={handleOnChange}
                value={overTimeLimitPay || ''}
              />
              <p>원</p>
            </InputWithP>
          </Label1>
          <Label1>
            <h5>정액권 연장 가능한 남은시간</h5>
            <InputWithP>
              <Input
                pattern="^[0-9]*$"
                type="text"
                min="0"
                name="timeTicketTimeLimitHour"
                onChange={handleOnChange}
                value={timeTicketTimeLimitHour || ''}
              />
              <p>시간</p>
              <Input
                pattern="^[0-9]*$"
                type="text"
                min="0"
                name="timeTicketTimeLimitMin"
                onChange={handleOnChange}
                value={timeTicketTimeLimitMin || ''}
              />
              <p>분</p>
            </InputWithP>
          </Label1>
          <ToggleContainer>
            <h5>연령 제한 사용 여부</h5>
            <ToggleBtn>
              <Input
                id="isAgeLimit"
                name="isAgeLimit"
                type="checkbox"
                checked={isAgeLimit}
                onChange={({ target }) => handleSingleCheck(target.checked, target)}
              />
              <span className="onoff-switch"></span>
            </ToggleBtn>
          </ToggleContainer>
          {isAgeLimit === 1 ? (
            <>
              <Label1>
                <h5>연령</h5>
                <InputWithP>
                  <p>만</p>
                  <Input
                    pattern="^[0-9]*$"
                    type="text"
                    min="0"
                    name="limitAge"
                    onChange={handleOnChange}
                    value={limitAge || ''}
                  />
                  <p>세 미만</p>
                </InputWithP>
              </Label1>
              <Label1>
                <h5>제한 시간</h5>
                <InputWithP>
                  <Input
                    style={{ width: '10rem' }}
                    type="time"
                    name="limitAgeStartTime"
                    onChange={handleOnChange}
                    value={limitAgeStartTime || ''}
                  />
                  ~
                  <Input
                    style={{ width: '10rem' }}
                    type="time"
                    name="limitAgeEndTime"
                    onChange={handleOnChange}
                    value={limitAgeEndTime || ''}
                  />
                </InputWithP>
              </Label1>
            </>
          ) : (
            <></>
          )}
          {/* <Label1>
                    <h5>멀티키오스크 설정</h5>
                    <p>*해당이 없는 경우 0을 입력해주세요</p>

                    <InputWithP>
                      <Input
                        style={{ width: "40%" }}
                        pattern="^[0-9]*$"
                        placeholder="숫자만"
                        type="text"
                        min="0"
                        name="multiKioskFloor"
                        onChange={handleOnChange}
                        value={multiKioskFloor || ""}
                      />
                    </InputWithP>
                  </Label1> */}
        </FormContent>

        <ButtonWrapper>
          <Button color="primary" className="mr-1" onClick={onSubmit}>
            수정하기
          </Button>
          <Button color="light" type="reset" onClick={MerchantId}>
            취소하기
          </Button>
        </ButtonWrapper>
      </PageContainer>
    </Fragment>
  );
};
export default MerchantSetting;
