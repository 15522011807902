import React, { Fragment, useEffect, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { getMessageLog, merchantId_api, userList } from '../lib/api';
import { messagelogListColumn } from '../data/columns';
import 'moment/locale/ko';
import PaginationComponent from '../components/pagination';
import { exportExcel } from '../components/common';
import DetailsModal from '../components/modal/detailsModal';
import Table, { TableHeaderContainerV2 } from '../components/common/Table';
import PageContainer from '../components/common/PageContainer';

import { ExcelButton } from '../components/common/Button';
import Search from '../components/common/Search';
import DatePickerComponent from '../components/filter/DatePicker';
import useHandleAPICall from '../lib/hooks/useHandleAPICall';
import useHandleError from '../lib/hooks/useHandleError';
import Format from '../util/formatter';
import { swalReturnWarning } from '../util/commonFunctions';

const MessageInfo = () => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [merchantId, setMerchantId] = useState(0);
  const [lists, setLists] = useState([]);
  const [startDate, setStartDate] = useState(moment().startOf('isoWeek').toDate());
  const [endDate, setEndDate] = useState(moment().endOf('isoWeek').toDate());
  const [searchKeyword, setSearchKeyword] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [totalLength, setTotalLength] = useState(0);

  //모달 state
  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userData, setUserData] = useState({});

  const getMerchantId = async () => {
    try {
      const { data } = await createRequest(merchantId_api);
      setMerchantId(data.id);
      getMessagelogListApi(data.id);
    } catch (error) {
      catchError(error);
    }
  };

  const getMessagelogListApi = async (merchantId, page) => {
    if (!merchantId) return;
    try {
      const { data } = await createRequest(
        getMessageLog,
        merchantId,
        moment(startDate).format('YYYY-MM-DD 00:00:00'),
        moment(endDate).format('YYYY-MM-DD 23:59:59'),
        page ? 1 : currentPage,
        postsPerPage,
        searchKeyword,
      );
      if (page) setCurrentPage(1);
      setTotalLength(data.count);
      if (data.result.length > 0) {
        setLists(
          data.result.map((item) => ({
            ...item,
            createdAt: moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            phone: (
              <div className="phoneNum-click" onClick={(e) => sendDetailData(item.phone)}>
                {Format.formatPhone(item.phone)}
              </div>
            ),
          })),
        );
      }
    } catch (error) {
      catchError(error);
    }
  };

  // 휴대혼 번호 가져오기
  const getUserList = async (phoneNum) => {
    try {
      const result = await createRequest(userList, merchantId, phoneNum);
      setUserData(result.data[0]);
      setDetailUserModal(true);
    } catch (error) {
      catchError(error);
    }
  };
  const exportExcelData = async () => {
    try {
      const data = await createRequest(
        getMessageLog,
        merchantId,
        moment(startDate).format('YYYY-MM-DD 00:00:00'),
        moment(endDate).format('YYYY-MM-DD 23:59:59'),
        1,
        totalLength,
        searchKeyword || '',
      );
      const excelData = [
        ...data.data.result.map((item) => ({
          ...item,
          createdAt: moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
        })),
      ];
      if (!excelData) return swalReturnWarning('엑셀로 출력할 수 있는 내역이 없습니다.');
      exportExcel(excelData, startDate, endDate, '메시지 전송 내역');
    } catch (error) {
      catchError(error);
    }
  };

  // 디테일 데이터 모달로 보내기
  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };

  //모달 on/off
  const closeDetailModal = () => {
    setDetailUserModal(false);
  };

  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword);
  };

  useEffect(() => {
    getMerchantId();
  }, []);

  useEffect(() => {
    getMessagelogListApi(merchantId);
  }, [startDate, endDate, currentPage]);

  return (
    <Fragment>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={closeDetailModal}
          seat={userData}
          back={true}
        />
      )}

      <PageContainer
        title="메시지 전송 내역"
        itemsRightSideTitle={[<ExcelButton onClick={() => exportExcelData()} />]}>
        <TableHeaderContainerV2>
          <DatePickerComponent
            nowTime="이번 주"
            withCalendar
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startMaxDate={endDate}
            endMinDate={startDate}
            maxPeriod={4}
          />

          <Search
            onSubmit={() => getMessagelogListApi(merchantId, 1)}
            searchKeyword={searchKeyword}
            setSearchKeyword={handleSearchKeyword}
          />
        </TableHeaderContainerV2>

        <Table
          data={lists}
          columns={messagelogListColumn}
          striped={true}
          center={true}
          customStyles={{ cells: { style: { padding: '0.5rem' } } }}
        />

        {totalLength > 0 && (
          <PaginationComponent
            setCurrentPage={setCurrentPage}
            setPostsPerPageAdd={setPostsPerPageAdd}
            setPostsPerPageAdd1={setPostsPerPageAdd1}
            setPageNum={setPageNum}
            totalLength={totalLength}
            postsPerPage={postsPerPage}
            currentPage={currentPage}
            pageNum={pageNum}
            postsPerPageAdd={postsPerPageAdd}
            postsPerPageAdd1={postsPerPageAdd1}
          />
        )}
      </PageContainer>
    </Fragment>
  );
};

export default MessageInfo;
