import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getSaleData } from '../../lib/api';
import useWindow from '../../lib/hooks/useWindow';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';
import useHandleError from '../../lib/hooks/useHandleError';

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  row-gap: 1rem;
  column-gap: 0.5rem;
  color: ${({ theme }) => theme.neutralGray[500]};
  font-size: 0.65rem;
  .mobile {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    p {
      color: #91979e;
    }
    strong {
      color: #91979e;
      font-weight: 400;
      margin: 0 0.5rem;
    }
  }
  @media screen and (max-width: 805px) {
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    font-size: 0.75rem;
    .mobile {
      width: 100%;
    }
    .pc,
    .mobile {
      justify-content: center;
    }
  }
  @media screen and (max-width: 360px) {
    font-size: 0.625rem;
  }
`;

const Divider = styled.div`
  height: 0.75rem;
  width: 1px;
  background: ${({ theme }) => theme.neutralGray[500]};
`;

const Footer = (props) => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [isMobile, setIsMobile] = useState(false);
  const [merchantData, setMerchantData] = useState({});
  const level = localStorage.getItem('level');
  const { user_name, store_address, contact, br_number, mail_order_sales_number } = merchantData;

  const windowSize = useWindow();

  const getMerchantInfo = async () => {
    try {
      if (level !== 'user') return;
      const res = await createRequest(getSaleData, props.data.id);
      setMerchantData(res.data[0]);
    } catch (error) {
      catchError(error);
    }
  };
  useEffect(() => {
    if (props?.data?.id) {
      getMerchantInfo(props.data.id);
    }
  }, [props?.data?.id]);

  useEffect(() => {
    if (!windowSize) return;
    setIsMobile(windowSize.width && windowSize.width < 804);
  }, [windowSize]);

  const footerStyle = {
    margin: 0,
    display: props?.data ? 'flex' : 'none',
    width: 'calc(100% - 14rem)',
    marginLeft: '14rem',
    alignItems: 'center',
    position: 'absolute',
    borderTop: '1px solid #DDE4EB',
  };

  const footerMobileStyle = {
    margin: 0,
    display: props?.data ? 'flex' : 'none',
    width: '100%',
    alignItems: 'center',
    position: 'relative',
    borderTop: '1px solid #DDE4EB',
  };
  return (
    <>
      {isMobile ? (
        <footer className="footer" style={footerMobileStyle}>
          <FooterContainer>
            <div className="mobile">
              <div className="pc">{`대표이사:${level === 'user' ? user_name : '심세용'}`}</div>
              <Divider />
              <div className="pc">상호명: 샘랩 주식회사</div>
            </div>
            <div className="mobile">
              {`주소: ${
                level === 'user'
                  ? store_address
                  : '경기도 시흥시 은계중앙로 362 수림프라자 902호 ,1001호'
              }`}
            </div>
            <div className="mobile">
              <div className="pc">
                사업자 등록번호: {level === 'user' ? br_number : '531-87-00830'}
              </div>
              <Divider />
              <div className="pc">문의: {level === 'user' ? contact : '01066594264'}</div>
            </div>
            <div className="mobile">
              {`통신판매업 신고번호: ${
                level === 'user' ? mail_order_sales_number : '2019-경기안산-0776'
              }`}
            </div>
          </FooterContainer>
        </footer>
      ) : (
        <footer className="footer" style={footerStyle}>
          <FooterContainer>
            <div className="pc">{`대표이사:${level === 'user' ? user_name : '심세용'}`}</div>
            <Divider />
            <div className="pc">상호명: 샘랩 주식회사</div>
            <Divider />
            <div className="pc">
              {`주소: ${
                level === 'user'
                  ? store_address
                  : '경기도 시흥시 은계중앙로 362 수림프라자 902호 ,1001호'
              }`}
            </div>
            <Divider />
            <div className="pc">
              사업자 등록번호: {level === 'user' ? br_number : '531-87-00830'}
            </div>
            <div className="pc">문의: {level === 'user' ? contact : '01066594264'}</div>
            <Divider />
            <div className="pc">
              {`통신판매업 신고번호: ${
                level === 'user' ? mail_order_sales_number : '2019-경기안산-0776'
              }`}
            </div>
          </FooterContainer>
        </footer>
      )}
    </>
  );
};

export default Footer;
