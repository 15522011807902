import React, { Fragment, useEffect } from 'react';
import Breadcrumbs from '../../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { getMileageLog, getUserData, userList } from '../../lib/api';
import { useState } from 'react';
import Swal from 'sweetalert2';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import { mileageColumn2 } from '../../data/columns';
import PaginationComponent from '../../components/pagination';
import DetailsModal from '../../components/modal/detailsModal';
import { numberWithCommas } from '../../components/common';
import { getInfo } from '../../lib/api/v2/user';
import Table from '../../components/common/Table';
import PageContainer from '../../components/common/PageContainer';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';
import useHandleError from '../../lib/hooks/useHandleError';

const StyledContainer = styled.div`
  width: 100%;
  .top-object {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1vh 0;
    .datepicker {
      width: 100%;
      display: flex;
      align-items: center;
      h5 {
        font-weight: 400;
      }
      > div > div > button {
        width: 100%;
      }
      select {
        background: #f8fcff;
        border: 1px solid #e9e9e9;
        border-radius: 4px;
        padding: 9px 12px;
        font-size: 14px;

        height: 38px;
      }
    }
  }
  .pagination-container {
    width: 100%;
    margin-top: 1vh;
    display: flex;
    justify-content: center;
  }
  .phoneNum-click {
    color: #04c9db;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 375px) {
    .top-object {
      width: 100%;
      .datepicker {
        flex-direction: column;
        select {
          width: 100%;
          text-align: center;

          padding: 9px 5px;
        }
      }
    }
  }
`;

const Mileage = () => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [merchantId, setMerchantId] = useState(-1);

  const [startDate, setStartDate] = useState(new Date('2020-01-01'));
  const [endDate, setEndDate] = useState(new Date('2030-01-01'));
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);

  const [mileageLog, setMileageLog] = useState([]);
  const [logCount, setLogCount] = useState(0);
  const [detailUserModal, setDetailUserModal] = useState(false);
  const [userData, setUserData] = useState({});

  const getData = async () => {
    try {
      const { data } = await createRequest(getInfo);
      setMerchantId(data.merchantId);
      getMileageData(data.merchantId, data.phoneNum);
    } catch (error) {
      catchError(error);
    }
  };

  const sendDetailData = (phoneNum) => {
    getUserList(phoneNum);
  };

  const getMileageData = async (mid, phoneNum) => {
    try {
      const data = await createRequest(
        getMileageLog,
        mid,
        moment(startDate).format('YYYY-MM-DD 00:00:00'),
        moment(endDate).format('YYYY-MM-DD 23:59:59'),
        currentPage,
        postsPerPage,
        phoneNum,
      );
      setMileageLog(
        data.data.result,
        data.data.result.map((item) => {
          item.usingTime = moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss');
          item.price = numberWithCommas(item.amount);
          item.phoneNumTag = (
            <div
              onClick={() => {
                sendDetailData(item.phoneNum);
              }}
              className="phoneNum-click">
              {item.phoneNum}
            </div>
          );
        }),
      );
      setLogCount(data.data.count);
    } catch (error) {
      catchError(error);
    }
  };

  const getUserList = async (phoneNum) => {
    try {
      const result = await createRequest(userList, merchantId, phoneNum);
      setUserData(result.data[0]);
      setDetailUserModal(!detailUserModal);
    } catch (error) {
      catchError(error);
    }
  };
  const showDetailModal = () => {
    setDetailUserModal(!detailUserModal);
  };

  useEffect(() => {
    getData();
  }, [startDate, endDate, currentPage]);

  return (
    <Fragment>
      {detailUserModal && (
        <DetailsModal
          isOpen={detailUserModal}
          close={showDetailModal}
          seat={userData}
          back={true}
        />
      )}
      <Breadcrumbs parent="마일리지 쿠폰 관리" title="마일리지" />
      <PageContainer title="마일리지 적립 / 사용 내역">
        <Table data={mileageLog} columns={mileageColumn2} striped={true} center={true} />
        {logCount > 0 && (
          <PaginationComponent
            setCurrentPage={setCurrentPage}
            setPostsPerPageAdd={setPostsPerPageAdd}
            setPostsPerPageAdd1={setPostsPerPageAdd1}
            setPageNum={setPageNum}
            totalLength={logCount}
            postsPerPage={postsPerPage}
            currentPage={currentPage}
            pageNum={pageNum}
            postsPerPageAdd={postsPerPageAdd}
            postsPerPageAdd1={postsPerPageAdd1}
          />
        )}
      </PageContainer>
    </Fragment>
  );
};

export default Mileage;
