import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Modal, ModalHeader, Row } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import styled from 'styled-components';
import { TabButton, TabList } from '../seatList';
import { getSaleData, ticketUsing, buyTicketList } from '../../lib/api';
import Swal from 'sweetalert2';
import {
  discountRate,
  getUserPayData,
  getUUID,
  numberWithCommas,
  swalMessage,
  tossPay,
} from '../../components/common';
import configDB from '../../data/customizer/config';
import Calendar from '../../components/modal/calendar';
import SeatMapviewModal from '../../components/modal/seatMapview';
import moment from 'moment';
import SelectPaymentType from '../../components/modal/selectPaymentType';
import { getInfo } from '../../lib/api/v2/user';
import KSPayWebForm, { requestKSPay } from '../../components/webpay/KSPAY';
import { getTicketKspayData } from '../../lib/api/v2/payment';
import { swalReturnWarning } from '../../util/commonFunctions';
import useModal from '../../lib/hooks/useModal';
import { commonWrapperOptions } from '../../components/modal/CommonWrapper';
import PageContainer from '../../components/common/PageContainer';
import Format from '../../util/formatter';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';
import useHandleError from '../../lib/hooks/useHandleError';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const TicketList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const TabButtonS = styled(TabButton)`
  width: 120px;
  @media screen and (max-width: 480px) {
    width: 85px;
    padding: 0;
    margin: 0;
  }
`;
const TicketS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto 10px;

  padding: 10px 15px;
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  box-shadow: inset -3px -3px 8px rgba(87, 120, 139, 0.18);
  border-radius: 16px;

  transition: transform 0.2s ease;
  p {
    margin-bottom: 0;
    letter-spacing: 0;
  }
  :active {
    opacity: 0.8;
    transform: scale(0.98);
  }
  position: relative;
  .ticketNameWithIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > div {
        :not(:last-child) {
          margin-right: 10px;
        }
      }

      strong {
        font-weight: 600;
        color: ${primary};
      }
    }
    > p {
      color: #222222;
      font-size: 18px;
      font-weight: 600;
    }
  }
  > p {
    :nth-child(1),
    :nth-child(2) {
      color: #555;
      line-height: 1.2;
    }
  }
  .ticketInfo {
  }
  .beforeCalc {
    color: #aaa;

    text-align: right;
    text-decoration: line-through;
  }
`;

export const Price = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    font-size: 18px;
    font-weight: 800;
    text-align: right;

    span {
      margin-left: 3px;
      font-size: 16px;
      font-weight: 400;
    }
  }
  strong {
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
  }
  .affterCalc {
    margin-left: 10px;
  }
  .percent {
    font-size: 12px; // 28px;
    color: red;
  }
`;
const SalesIcon = styled.div`
  width: 40px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${primary};
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
`;

const secondToHours = (num) => {
  const h = Math.floor(num / 3600);
  const m = Math.floor((num % 3600) / 60);
  // const s = Math.floor((num % 3600) % 60);
  return h > 0 ? (m > 0 ? `${h}시간 ${m}분` : `${h}시간`) : `${m}분`;
};
const UserTicketBuy = () => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const { openModal } = useModal();

  const [userName, setUserName] = useState('');
  const [tabData, setTabData] = useState(1);
  const [ticketlist, setTicketList] = useState([]);
  const [merchantId, setMerchantId] = useState(0);
  const [phoneNum, setPhoneNum] = useState(null);
  const [selected, setSelected] = useState(null);
  const [clientKey, setClientKey] = useState('');
  const [isRefundText, setIsRefundText] = useState(false);
  const [isCalendar, setIsCalendar] = useState(false);
  const [isOpenSeatView, setIsOpenSeatView] = useState(false);
  const [usingArr, setUsingArr] = useState(false);
  const [choiceStartDate, setChoiceStateDate] = useState(null);
  const [choiceSeatId, setChoiceSeatId] = useState(null);
  // const [isSelectPaymentModal, setIsSelectPaymentModal] = useState(false);

  const [payData, setPayData] = useState({ isReady: false });
  const [logData, setLogData] = useState({
    ticketItmeId: '',
    merchantId: '',
    phoneNum: '',

    startDate: '',
    day: '',
    remainTime: '',

    seatId: '',
  });
  const [choiceSeatNum, setChoiceSeatNum] = useState(null);
  const [storeId, setStoreId] = useState({});
  const getUserInfo = async () => {
    try {
      const { data } = await createRequest(getInfo);
      const { merchantId, phoneNum, userId, userName } = data;
      setPhoneNum(phoneNum);
      setUserName(userName);
      setMerchantId(merchantId);
      getProductList(merchantId);

      if (data) {
        const res = await createRequest(getSaleData, merchantId);
        setClientKey(res.data[0].toss_client_key);
      }
      const res = await createRequest(ticketUsing, merchantId, userId);
      if (res.data.legnth > 0) {
        setUsingArr(true);
      } else {
        setUsingArr(false);
      }
    } catch (err) {
      catchError(err);
    }
  };

  const getProductList = async (merchantId) => {
    try {
      const res = await createRequest(buyTicketList, merchantId);
      if (res.status === 200) {
        setTicketList(res.data);
      } else {
        Swal.fire({
          icon: 'error',
          timer: 3000,
          title: '정보를 불러올 수 없습니다. \n 다시 시도해주세요.',
        });
      }
    } catch (err) {
      catchError(err);
      console.log(err);
    }
  };
  useEffect(() => {
    getUserInfo();
  }, []);

  /**
   * @deprecated 사용자 결제 PG사는 KSPay로 변경되었습니다.
   * 결제 정보 검증 (헥토파이낸셜)
   * @param {*} list
   */
  const processPayDataHecto = async (list) => {
    try {
      const storeId = await createRequest(getUserPayData, merchantId);

      if (storeId.simple_store_id || storeId.credit_store_id) {
        const today = moment(new Date());

        setPayData({
          // id: chergeItemData[choicePoint].id,
          name: list.T_name,
          mid: merchantId,
          amount: discountRate(list.T_price, list.T_discount),
          simple_store_id: storeId.simple_store_id,
          credit_store_id: storeId.credit_store_id,
          // 결제수단 판별을 위한 type
          type: 'ticket',
        });
        setLogData({
          ticketItemId: list.id,
          merchantId: merchantId,
          phoneNum: phoneNum,
          startDate: choiceStartDate
            ? moment(choiceStartDate).format('YYYY-MM-DD')
            : moment(today).format('YYYY-MM-DD'),
          startTime: choiceStartDate
            ? moment(choiceStartDate).format('HH:mm:ss')
            : moment(today).format('HH:mm:ss'),
          day: list.T_type === 2 ? list.T_day : '',
          remainTime: list.T_type === 3 || list.T_type === 1 ? list.T_time : '',
          expireDate: list.T_type === 3 ? list.T_expireDate : '',
          seatId: list.T_type === 1 ? choiceSeatId : '',
          seatNumber: list.T_type === 1 ? choiceSeatNum : '',
        });
        // setIsSelectPaymentModal(true);
      }
    } catch (error) {
      swalReturnWarning('결제 정보 검증에 실패했습니다.\n잠시 후 다시 시도해 주세요.');
    }
  };

  /**
   * 결제 정보 검증 (KSPAY)
   * @param {*} list
   */
  const processPayDataKspay = async (list) => {
    try {
      const { data } = await createRequest(getTicketKspayData, {
        ticketItemId: list.id,
        startDate: choiceStartDate
          ? moment(choiceStartDate).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        remainTime: list.T_type === 3 || list.T_type === 1 ? list.T_time : '',
        seatId: list.T_type === 1 || (list.T_type === 2 && list.seatType === 1) ? choiceSeatId : '',
      });
      setPayData({
        ...payData,
        ...data,
        isReady: true,
        name: list.T_name,
        userName: userName || phoneNum.slice(-4),
      });
    } catch (error) {
      catchError(error);
    }
  };

  const handleSelectedItem = (list) => {
    setSelected(list);
    if (clientKey === 'ON')
      return Swal.fire({
        icon: 'error',
        title: '결제 준비중입니다.',
        timer: 3000,
      });

    if (list?.T_type === 2 && list?.seatType === 1) {
      if (usingArr)
        return Swal.fire({
          icon: 'error',
          title: '이미 이용중인 이용권이 있습니다.',
          timer: 3000,
        });
      return openModal(
        () => (
          <SeatMapviewModal
            onClose={() => setIsCalendar(true)}
            merchantId={merchantId}
            ticketItemId={list.id}
            selected={selected}
            setChoiceSeatId={setChoiceSeatId}
            setChoiceSeatNum={setChoiceSeatNum}
          />
        ),
        commonWrapperOptions,
      );
    } else if (list?.T_type === 2 && !choiceStartDate) {
      setIsCalendar(true);
    } else if (list?.T_type === 1 && !choiceSeatId) {
      if (usingArr) {
        Swal.fire({
          icon: 'error',
          title: '이미 이용중인 이용권이 있습니다.',
          timer: 3000,
        });
      } else {
        openModal(
          () => (
            <SeatMapviewModal
              onClose={() => setIsOpenSeatView(!isOpenSeatView)}
              merchantId={merchantId}
              ticketItemId={list.id}
              selected={selected}
              setChoiceSeatId={setChoiceSeatId}
              setChoiceSeatNum={setChoiceSeatNum}
            />
          ),
          commonWrapperOptions,
        );

        // setIsOpenSeatView(true);
      }
    } else {
      checkItem(list);
    }

    // setSelected(list);
  };

  const resetSelection = () => {
    setChoiceSeatId(null);
    setChoiceStateDate(null);
  };

  const checkItem = (list) => {
    const ticketName = list.T_name;
    const time = list.T_day !== 0 ? `${list.T_day}일권` : `${secondToHours(list.T_time)}`;
    const level = `${list.T_level}등급 좌석 이용 가능`;
    const until = `${list.T_type === 3 ? `유효기간 ${list.T_expireDate}일` : ''}`;
    const cost = `${Format.formatNumber(discountRate(list.T_price, list.T_discount))}원`;

    Swal.fire({
      icon: 'question',
      title: '구매하실 상품을 확인해 주세요.',
      html: `상품명: ${ticketName}<br />
      이용기간: ${time}<br />
      ${level}<br />
      ${until ? `${until}<br />` : ''}
      금액: ${cost}`,
      //   Swal.fire({
      //     icon: 'question',
      //     title: '구매하실 상품을 확인해주세요.',
      //     text: `${list.T_name} \n ${
      //       list.T_day !== 0 ? `${list.T_day}일권` : `${secondToHours(list.T_time)}`
      //     }, ${list.seatType === 1 ? '고정석, ' : ''}
      // ${list.T_level}등급 좌석 이용 가능\n
      // ${list.T_type === 3 ? `, 유효기간 ${list.T_expireDate}일` : ''} \n\n ${discountRate(
      //       list.T_price,
      //       list.T_discount,
      //     )}원`,

      showCancelButton: true,
      confirmButtonColor: primary,
      cancelButtonColor: '#999',
      confirmButtonText: '결제',
      cancelButtonText: '취소',
    }).then(async (result) => {
      try {
        if (result.isConfirmed) {
          // processPayDataHecto(list);
          try {
            const result = await createRequest(getUserPayData, merchantId);
            if (!result.credit_store_id)
              return swalMessage(
                'warning',
                '모바일 결제를 사용할 수 없는 지점입니다.\n관리자에게 문의해 주세요.',
                '확인',
                false,
              );
            processPayDataKspay(list);
          } catch (error) {
            catchError(error);
          }
        } else resetSelection();
      } catch (error) {
        resetSelection();
      }
    });
  };

  useEffect(() => {
    if (selected?.seatType === 1 && !choiceStartDate) return;
    if (selected?.seatType === 1 && choiceStartDate) return checkItem(selected);
    if (choiceStartDate && selected) return handleSelectedItem(selected);
    if (choiceSeatId && selected) return handleSelectedItem(selected);
    return setChoiceStateDate(null);
  }, [choiceStartDate, choiceSeatId]);

  // useEffect(() => {
  //   if (localStorage.getItem("startDate") && selected) {
  //     handleSelectedItem(selected);
  //   } else if (localStorage.getItem("seat") && selected) {
  //     handleSelectedItem(selected);
  //   } else {
  //     localStorage.removeItem("startDate");
  //   }
  // }, [localStorage.getItem("startDate"), localStorage.getItem("seat")]);

  // useEffect(() => {
  //   localStorage.removeItem("startDate");
  //   localStorage.removeItem("seat");
  // }, []);

  // const closePaymentModal = () => {
  //   setIsSelectPaymentModal(false);
  //   setChoiceSeatId(null);
  //   setChoiceStateDate(null);
  // };

  return (
    <>
      <KSPayWebForm payData={payData} />
      {/* {isSelectPaymentModal && (
        <SelectPaymentType
          show={isSelectPaymentModal}
          onClose={() => closePaymentModal()}
          // data={merchantDefault}
          payData={payData}
          logData={logData}
        />
      )} */}
      {/* {isOpenSeatView && (
        <SeatMapviewModal
          show={isOpenSeatView}
          onClose={() => setIsOpenSeatView(!isOpenSeatView)}
          merchantId={merchantId}
          selected={selected}
          setChoiceSeatId={setChoiceSeatId}
          setChoiceSeatNum={setChoiceSeatNum}
        />
      )} */}
      {isCalendar && (
        <Calendar
          show={isCalendar}
          onClose={() => setIsCalendar(!isCalendar)}
          selected={selected}
          clientKey={clientKey}
          setChoiceStateDate={setChoiceStateDate}
        />
      )}
      {isRefundText && (
        <Modal centered isOpen={isRefundText} toggle={() => setIsRefundText(!isRefundText)}>
          <ModalHeader className="modal-title" toggle={() => setIsRefundText(!isRefundText)}>
            <p style={{ marginBottom: 0 }}>
              ① 회원은 회사에 서비스 환불을 요구할 수 있습니다.
              <br /> 이때 월간 사용 기간이 종료될 때까지 서비스를 계속 이용할 수 있습니다. <br />
              회원별 환불정책은 다음과 같습니다.
              <br />
              <br />
              기간권 : 일회권 24시간 기준 일할 계산 후 차감하여 환불 처리 합니다
              <br />
              시간정액권 회원 : 일회권 24시간 기준 일할 계산 후 차감하여 환불 처리 합니다.
              <br />
              지점 정책에 따라 환불이 불가할 수 있습니다.
            </p>
          </ModalHeader>
        </Modal>
      )}
      <Breadcrumb parent="상품관리" title="상품목록" />

      <PageContainer title="입장권 구입">
        <TabList>
          <div>
            <TabButtonS selected={tabData === 1} onClick={() => setTabData(1)}>
              일회권
            </TabButtonS>
            <TabButtonS selected={tabData === 2} onClick={() => setTabData(2)}>
              기간권
            </TabButtonS>
            <TabButtonS selected={tabData === 3} onClick={() => setTabData(3)}>
              시간정액권
            </TabButtonS>
            {/* <TabButtonS
                    selected={tabData === 5}
                    onClick={() => setTabData(5)}
                  >
                    사물함
                  </TabButtonS> */}
          </div>
        </TabList>
        {ticketlist.length > 0 ? (
          <TicketList>
            {ticketlist
              .filter((list) => list.T_type === tabData)
              .sort((a, b) => a.T_sort - b.T_sort)
              .map((list) => (
                <div key={list.id} style={{ position: 'relative' }}>
                  <TicketS
                    discount={list?.T_discount !== 0}
                    onClick={() => handleSelectedItem(list)}>
                    <div className="ticketNameWithIcons">
                      <p>{list.T_name}</p>
                      <div className="icons">
                        {list?.T_discount !== 0 && <SalesIcon>할인</SalesIcon>}
                        {list?.T_count > 0 && (
                          <div>
                            <p>
                              남은수량 <strong>{list?.T_count}</strong>개
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    {list?.T_description && <p>{list.T_description}</p>}
                    <p className="ticketInfo">
                      {list.T_day !== 0 ? `${list.T_day}일권` : `${secondToHours(list.T_time)}`},{' '}
                      {list.seatType === 1 && '고정석, '}
                      {list.T_level}등급 좌석 이용 가능
                      {list.T_type === 3 && `, 유효기간 ${list.T_expireDate}일`}
                    </p>

                    {/* {list.T_type === 2 && (
                            <CheckedBtns>
                              <CheckedBtn>
                                <div className="circle" />
                                <img src={check} alt="checkIcon" />
                                <p> 바로 사용</p>
                              </CheckedBtn>
                              <CheckedBtn>
                                <div className="circle" />
                                <img src={check} alt="checkIcon" />
                                <p>기간 직접 입력</p>
                              </CheckedBtn>
                            </CheckedBtns>
                          )} */}
                    <div style={{ marginTop: '10px' }}>
                      <Price discount={list?.T_discount !== 0}>
                        {list?.T_discount !== 0 && <p className="percent">{list.T_discount}%</p>}
                        <p className="affterCalc">
                          {numberWithCommas(discountRate(list.T_price, list.T_discount))}
                          <span>원</span>
                        </p>
                      </Price>
                      {list?.T_discount !== 0 && (
                        <p className="beforeCalc">{numberWithCommas(list.T_price)}원</p>
                      )}
                    </div>
                  </TicketS>
                </div>
              ))}
            <p style={{ textDecoration: 'underline' }} onClick={() => setIsRefundText(true)}>
              환불정책 &rarr;
            </p>
          </TicketList>
        ) : (
          <h6>등록된 상품이 없습니다.</h6>
        )}
      </PageContainer>
    </>
  );
};
export default UserTicketBuy;
