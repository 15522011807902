import React, { useState } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import {
  getPaylogList,
  getUserTicketList,
  merchantId_api,
  userLog,
  userTicketAccess,
  createMemo,
  getMemo,
  deleteMemo,
  getMileageLog,
  userInfoUpdate,
  userList,
} from '../../lib/api';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import {
  mileageColumn2,
  paylogListColumns1,
  userLogColumn,
  userTicketListColumn,
} from '../../data/columns';
import PaginationComponent from '../pagination';
import { sendQrCode } from '../../lib/socket';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { TabButton, ToggleBtn } from '../../pages/seatList';
import { AddBtn } from '../../pages/lockerChange';
import MileageAddModal from '../modal/mileageAddModal';
import { ModalBackground } from './choiceModal';
import { numberWithCommas } from '../common';
import { BackgroundLoading } from '../Loading';
import Table from '../common/Table';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';
import useHandleError from '../../lib/hooks/useHandleError';
import { swalReturnWarning } from '../../util/commonFunctions';
import { datadogLogs } from '@datadog/browser-logs';
import Format from '../../util/formatter';

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 20px;
  margin: 10px 0;
  width: 100%;
  overflow-y: auto;
  border-bottom: solid 1px #ccc;
  > div > button {
    width: 100px;
    margin-right: 0;
  }
  @media screen and (max-width: 480px) {
    > div {
      width: 100%;
      display: flex;
    }
    flex-direction: column-reverse;
    padding: 0 10px;
    > div > button {
      /* width: 65px; */
      font-size: 13px;
      margin-right: 1px;
    }
  }
`;

const TabList1 = styled(ToggleWrapper)`
  @media screen and (max-width: 480px) {
    > div {
      flex-wrap: wrap;
    }
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 0;
  > p {
    word-break: keep-all;
    text-align: center;
    margin-bottom: 0;
  }
  @media screen and (max-width: 480px) {
    width: 96%;
    margin-bottom: 0px;
  }
`;
const Btns = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const MemoContainer = styled.div`
  display: flex;
  width: 100%;
  height: 75%;
  position: relative;
  margin: 1vw 0;
  gap: 1vw;
  .add-memo {
    width: 40%;
    padding: 1vw;
    border: 1px solid #e9e9e9;
    border-radius: 0.25rem;
    font-size: 18px;
    font-weight: 500;
    textarea {
      width: 100%;
      height: 10vh;
      margin: 10px 0;
      border: 1px solid #e6e4e9;
      border-radius: 0.25rem;
      resize: none;
    }
    button {
      background-color: #04c9db;
      border-style: none;
      border-radius: 0.25rem;
      width: 100%;
      height: 45px;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      &:hover {
        background-color: #4433ff;
      }
    }
  }
  .memo-list {
    width: 60%;
    padding: 1vw;
    border-radius: 0.25rem;
    border: 1px solid #e9e9e9;
    overflow-y: scroll;
    .memo-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
      border-bottom: 1px solid #e9e9e9;
    }
    .no-memo {
      font-size: 16px;
      font-weight: 500;
      margin: 10px 0;
      color: #757575;
    }
    li {
      box-sizing: border-box;
      width: 100%;
      min-height: 65px;
      max-height: 140px;
      margin: 10px 0;
      padding: 10px;
      border-radius: 0.25rem;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
      .memo {
        font-size: 16px;
        font-weight: 500;
      }
      .created {
        color: #505050;
      }
    }
    button {
      background-color: #fafafa;
      border-style: none;
      border-radius: 0.25rem;
      width: 40px;
      height: 20px;
      text-align: center;
      color: #04c9db;
      &:hover {
        background-color: #cccccc;
      }
    }
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 20px;
    .add-memo {
      width: 100%;
      padding: 10px;
      font-size: 14px;
      button {
        font-size: 14px;
        height: 40px;
      }
    }
    .memo-list {
      width: 100%;
      margin-bottom: 20px;
      padding: 10px;
      padding-right: 1px;
      .memo-title {
        font-size: 14px;
      }
      .no-memo {
        font-size: 12px;
      }
      li {
        .memo {
          font-size: 12px;
        }
      }
      .created {
        button {
          padding: 1px 3px;
        }
      }
    }
  }
`;

const DatePickerStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > div {
    width: 35%;
    display: flex;
    align-items: center;
  }
  > ${AddBtn} {
    margin-bottom: 5px;
    width: 170px;
  }
  margin: 0 10px;
  h5 {
    margin: 0 8px;
    font-weight: 400;
  }
  > div > div > div > button {
    width: 100%;
  }
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 9px 12px;
    font-size: 14px;
    margin-left: 10px;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    width: 100%;
    margin: 5px 0 0;
    > div {
      width: 100%;
    }
    > ${AddBtn} {
      margin-top: 5px;
      margin-bottom: 0px;
      width: 100%;
    }
    > div > div > div > button {
      padding: 6px 10px;
      font-size: 13px;
      > svg {
        width: 17px;
      }
    }
    select {
      padding: 9px 5px;
    }
  }
`;
const ParentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  input {
    width: 75%;
  }
`;
let mid;
const DetailsModal = ({ isOpen, close, seat, back, setData }) => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  //isOpen: boolean(true일경우 showModal), close(useState함수(모달을 끄기위한함수)), seat:해당하는 유저의 정보.(userListAPI를 사용하여 값을 가져올것.), back:백그라운드 출력(true), setData
  const [startDate, setStartDate] = useState(new Date(2011, 1, 1));
  const [endDate, setEndDate] = useState(new Date(2041, 12, 31));
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [totalLength, setTotalLength] = useState(0);
  const [totalLength2, setTotalLength2] = useState(0);
  const [totalLength3, setTotalLength3] = useState(0);
  const [totalLength4, setTotalLength4] = useState(0);
  const [dataList, setDataList] = useState([]);
  const [tabMenuBar, setTabMenuBar] = useState(0);
  const [userPayData, setUserPayData] = useState([]);
  const [userInOutData, setUserInOutLog] = useState([]);
  const [accessCheck, setAccessCheck] = useState(seat?.easyTicketAccessUser);
  const [smsDenyCheck, setSmsDenyCheck] = useState(seat?.smsDeny);
  const [mileage, setMileage] = useState([]);
  const [superEnterCheck, setSuperEnterCheck] = useState(seat?.superEnter);
  const [memo, setMemo] = useState('');
  const [memolist, setMemoList] = useState([]);
  const [memoTrigger, setMemoTrigger] = useState(false);
  const [isMileageAdd, setIsMileageAdd] = useState(false);
  const [parentPhoneNum, setParentPhoneNum] = useState(seat?.parentPhoneNum);
  //로딩componernt
  const [isLoading, setIsLoading] = useState(true);

  //userId
  const [userId, setUserId] = useState(seat?.userId ? seat?.userId : seat?.id);

  const toggleMileageModal = () => {
    setIsMileageAdd(!isMileageAdd);
  };
  const getUserticketData = async (i) => {
    setIsLoading(true);
    if (!seat) return;
    try {
      const { data } = await createRequest(
        getUserTicketList,
        i,
        moment(startDate).format('YYYYMMDD000000'),
        moment(endDate).format('YYYYMMDD235959'),
        currentPage,
        postsPerPage,
        seat.phoneNum,
      );
      setDataList([
        ...data.result.map((item) => ({
          ...item,
          T_isUsing:
            item.isUsing === 1 ? (
              <div>사용중</div>
            ) : item.isUsing === 2 ? (
              <div style={{ color: '#F04E36' }}>시간 초과</div>
            ) : item.lastOut ? (
              <div style={{ color: '#53852A' }}>퇴장완료</div>
            ) : (
              <div>사용전</div>
            ),
          type:
            item.type === 1
              ? ` 일회권`
              : item.type === 2
              ? ` 기간권 - ${item.seatType === 0 ? '자유석' : '고정석'}`
              : item.type === 3
              ? ` 시간정액권`
              : item.type === 4
              ? ' 세미나실'
              : '사물함',
          start: moment(item.startDate).format('YYYY-MM-DD HH:mm:ss'),
          end: moment(item.endDate).format('YYYY-MM-DD HH:mm:ss'),
        })),
      ]);
      setTotalLength(data.count);
      setIsLoading(false);
    } catch (error) {
      catchError(error);
    }
  };
  const payData = async (i) => {
    setIsLoading(true);
    try {
      const { data } = await createRequest(
        getPaylogList,
        i,
        moment(startDate).format('YYYYMMDD000000'),
        moment(endDate).format('YYYYMMDD235959'),
        currentPage,
        postsPerPage,
        seat.phoneNum,
      );
      setUserPayData([
        ...data.info.map((item) => ({
          ...item,
          ticketType:
            item.type === 1
              ? ` 일회권`
              : item.type === 2
              ? ` 기간권 - ${item.seatType === 0 ? '자유석' : '고정석'}`
              : item.type === 3
              ? ` 시간정액권`
              : item.type === 4
              ? ' 세미나실'
              : item.type === 5
              ? '사물함'
              : item.meta8?.includes('자판기')
              ? '자판기'
              : item.meta8?.includes('컵코인')
              ? '컵코인'
              : item.meta8?.includes('백오피스')
              ? '백오피스'
              : item.meta8?.includes('이전 데이터')
              ? '이전 데이터'
              : '-',
          price: item.amount === 0 ? 0 : !item.amount ? '-' : numberWithCommas(item.amount),
          createdAt: moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          cancelType: item.paymentType?.includes('cancel') ? (
            <span
              style={{
                textDecorationLine: 'line-through',
                color: '#E00F2C',
              }}>
              결제완료(환불완료)
            </span>
          ) : (
            <span>결제완료</span>
          ),
        })),
      ]);
      setTotalLength3(data.count);
      setIsLoading(false);
    } catch (error) {
      catchError(error);
    }
  };

  const getUserLogData = async (i) => {
    try {
      setIsLoading(true);
      const { data } = await createRequest(
        userLog,
        i,
        moment(startDate).format('YYYYMMDD000000'),
        moment(endDate).format('YYYYMMDD235959'),
        currentPage,
        postsPerPage,
        seat.phoneNum,
        +totalLength2,
      );
      setUserInOutLog([
        ...data.result.map((item, index) => ({
          ...item,
          idx: item.id,
          id: item.id * index,
          create: moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          seatNumber1: `${item.seatNumber ? Format.formatNumber(item.seatNumber) : '-'}번`,
        })),
      ]);
      if (data.count > 0) setTotalLength2(data.count);
      setIsLoading(false);
    } catch (error) {
      catchError(error);
    }
  };

  const getUserData = async (merchantId, phoneNum) => {
    try {
      const resultData = await createRequest(userList, mid, phoneNum);
      setSmsDenyCheck(resultData.data[0].smsDeny);
      setAccessCheck(resultData.data[0].easyTicketAccessUser);
      setSuperEnterCheck(resultData.data[0].superEnter);
      setUserId(resultData.data[0].id);
    } catch (error) {
      catchError(
        error,
        '회원 설정기능 관련 데이터를 가져오는 도중 에러가 발생했습니다.\n다시 시도해주세요.',
      );
    }
  };

  const getMerchantId = async () => {
    try {
      const data = await createRequest(merchantId_api);
      mid = data.data.id;
      if (!seat?.phoneNum) return;
      getUserticketData(data.data.id);
      getUserLogData(data.data.id);
      payData(data.data.id);
      getMileage(data.data.id);
      if (seat?.phoneNum) getMemoList();
      if (!seat?.smsDeny) getUserData(data.data.id, seat?.phoneNum);
    } catch (error) {
      catchError(error);
    }
  };

  const accessChange = async () => {
    try {
      if (!seat?.phoneNum) return close();
      await createRequest(userTicketAccess, {
        smsDeny: smsDenyCheck,
        access: accessCheck,
        userId: userId,
        superEnter: superEnterCheck,
        merchantId: seat.merchantId,
      });
      close();
      if (setData) {
        setData(seat.merchantId);
      }
    } catch (error) {
      catchError(error);
    }
  };

  //getMerchantId 나누기

  useEffect(() => {
    if (tabMenuBar === 0 && mid) {
      //이용권
      getUserticketData(mid);
    } else if (tabMenuBar === 1) {
      //입장권
      getUserLogData(mid);
    } else if (tabMenuBar === 2) {
      //결제정보
      payData(mid);
    } else if (tabMenuBar === 3) {
      //메모
      if (seat?.phoneNum) getMemoList();
    } else if (tabMenuBar === 4) {
      //마일리지
      getMileage(mid);
    }
  }, [currentPage, tabMenuBar]);

  const memoHandler = (e) => {
    setMemo(e.target.value);
    if (e.target.value) {
      setMemoTrigger(true);
    } else {
      setMemoTrigger(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const data = {
      merchantId: seat.merchantId,
      phoneNum: seat.phoneNum,
      memo: memo,
    };

    if (memo && memoTrigger) {
      try {
        const res = await createRequest(createMemo, data);
        if (res.status === 200) {
          getMemoList();
          document.getElementById('textarea').value = '';
          setMemoTrigger(false);
        } else if (res.status === 202) {
          Swal.fire({
            icon: 'error',
            title: '메모 작성에 실패했습니다.',
            timer: 2000,
          });
        }
      } catch (err) {
        catchError(err);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: '내용을 입력해주세요.',
      });
    }
  };

  const getMemoList = async () => {
    const merchantId = seat.merchantId;
    const phoneNum = seat.phoneNum;
    setIsLoading(true);
    try {
      const res = await createRequest(getMemo, merchantId, phoneNum);

      if (res.status === 200) {
        setMemoList(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      catchError(err);
    }
  };

  const deleteHandler = async (id) => {
    const merchantId = seat.merchantId;
    try {
      const res = await createRequest(deleteMemo, merchantId, id);
      if (res.status === 200) {
        getMemoList();
      }
    } catch (err) {
      catchError(err);
    }
  };

  const getMileage = async (e) => {
    setIsLoading(true);
    try {
      const data = await createRequest(
        getMileageLog,
        e,
        moment(startDate).format('YYYY-MM-DD 00:00:00'),
        moment(endDate).format('YYYY-MM-DD 23:59:59'),
        currentPage,
        postsPerPage,
        seat.phoneNum,
      );
      if (data.status === 200) {
        setMileage(
          data.data.result,
          data.data.result.map((item) => {
            item.usingTime = moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss');
            item.price = item.amount === 0 ? 0 : !item.amount ? '-' : numberWithCommas(item.amount);
          }),
        );
        setTotalLength4(data.data.count);
        setIsLoading(false);
      }
    } catch (error) {
      catchError(error);
    }
  };

  const userUpdate = async () => {
    try {
      await createRequest(userInfoUpdate, {
        merchantId: seat.merchantId,
        userId: userId,
        parentPhoneNum: parentPhoneNum,
      });
      Swal.fire({
        icon: 'success',
        title: '학부모 번호가 변경되었습니다.',
        timer: 2000,
      });
    } catch (error) {
      catchError(error, '변경하는 도중 에러가 발생했습니다. 잠시 후 다시 시도해 주세요.');
    }
  };

  const handleIssueBarcode = (merchantId, phoneNum, qrCode) => {
    if (!qrCode) {
      datadogLogs.logger.warn(
        `[Backoffice] Barcode data not found. [MerchantId]: ${merchantId} [PhoneNumber]: ${phoneNum} [QR]: ${qrCode}`,
      );
      return swalReturnWarning(
        '바코드를 발급받지 않은 회원입니다.\n회원이 바코드를 발급받은 경우에만 출력이 가능합니다.',
      );
    }
    datadogLogs.logger.info(
      `[Backoffice] Barcode issued. [MerchantId]: ${merchantId} [PhoneNumber]: ${phoneNum} [QR]: ${qrCode}`,
    );
    sendQrCode(merchantId, phoneNum, qrCode);
  };

  const parentPhoneNumChange = (e) => {
    var regex = /[^0-9]/g;
    setParentPhoneNum(e.target.value.replace(regex, ''));
  };

  useEffect(() => {
    getMerchantId();
  }, []);

  const onChangeEvent = () => {
    if (+superEnterCheck === 1) {
      setSuperEnterCheck(0);
    } else if (+superEnterCheck === 0) {
      setSuperEnterCheck(1);
    }
  };

  return (
    <>
      {isMileageAdd && (
        <MileageAddModal
          isOpen={isMileageAdd}
          onClose={toggleMileageModal}
          user={seat}
          userId={userId}
          reset={() => getMileage(mid)}
        />
      )}

      <Modal
        style={{
          minWidth: window.innerWidth < 768 ? '80vw' : window.innerWidth < 480 ? '' : '60vw',
        }}
        centered
        isOpen={isOpen}
        toggle={accessChange}>
        {isLoading && <BackgroundLoading />}
        {seat?.phoneNum ? (
          <ModalHeader
            style={{ display: ' flex', alignItems: 'center' }}
            toggle={() => {
              accessChange();
            }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <span style={{ wordBreak: 'keep-all' }}>
                <span style={{ userSelect: 'text' }}>{seat?.phoneNum}</span> 회원정보
              </span>
              <button
                className="btn-primary btn-sm"
                onClick={() => handleIssueBarcode(seat.merchantId, seat.phoneNum, seat.qrCode)}>
                바코드 출력
              </button>
            </div>
          </ModalHeader>
        ) : (
          <ModalHeader
            className="modal-title"
            toggle={() => {
              accessChange();
            }}>
            지점정보가 이전된 회원정보입니다.
          </ModalHeader>
        )}

        {seat?.phoneNum && (
          <>
            <ToggleWrapper style={{ borderBottom: 'none' }}>
              <Btns>
                <ToggleContainer>
                  <p>슈퍼입장</p>
                  <ToggleBtn>
                    <input
                      type="checkbox"
                      value={superEnterCheck}
                      checked={superEnterCheck === 1}
                      disabled={superEnterCheck === 2}
                      onChange={onChangeEvent}
                    />
                    <span className="onoff-switch"></span>
                  </ToggleBtn>
                </ToggleContainer>
                <ToggleContainer>
                  <p>문자 차단 여부</p>
                  <ToggleBtn>
                    <input
                      type="checkbox"
                      value={smsDenyCheck}
                      checked={smsDenyCheck}
                      onChange={() => setSmsDenyCheck(!smsDenyCheck)}
                    />
                    <span className="onoff-switch"></span>
                  </ToggleBtn>
                </ToggleContainer>

                <ToggleContainer>
                  <p>QR/PIN 제한해제</p>
                  <ToggleBtn>
                    <input
                      type="checkbox"
                      value={accessCheck}
                      checked={accessCheck}
                      onChange={() => setAccessCheck(!accessCheck)}
                    />
                    <span className="onoff-switch"></span>
                  </ToggleBtn>
                </ToggleContainer>
              </Btns>
            </ToggleWrapper>
            <TabList1>
              <div style={{ display: 'flex' }}>
                <TabButton
                  selected={tabMenuBar === 0}
                  onClick={() => {
                    setTabMenuBar(0);
                    setCurrentPage(1);
                  }}>
                  이용권
                </TabButton>
                <TabButton
                  selected={tabMenuBar === 1}
                  onClick={() => {
                    setTabMenuBar(1);
                    setCurrentPage(1);
                  }}>
                  입퇴장
                </TabButton>
                <TabButton
                  selected={tabMenuBar === 2}
                  onClick={() => {
                    setTabMenuBar(2);
                    setCurrentPage(1);
                  }}>
                  결제정보
                </TabButton>

                <TabButton
                  selected={tabMenuBar === 4}
                  onClick={() => {
                    setTabMenuBar(4);
                    setCurrentPage(1);
                  }}>
                  마일리지
                </TabButton>
                <TabButton
                  selected={tabMenuBar === 3}
                  onClick={() => {
                    setTabMenuBar(3);
                    setCurrentPage(1);
                  }}>
                  메모
                </TabButton>
              </div>
            </TabList1>
            <ModalBody
              style={{
                minHeight: window.innerHeight > 480 ? '65vh' : '77.2vh',
              }}>
              <DatePickerStyle>
                {/* <div>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                selectsStart
                locale={ko}
                startDate={startDate}
                maxDate={endDate}
                endDate={endDate}
                customInput={<ExampleCustomInput />}
              />
              <h5> - </h5>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                locale={ko}
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                customInput={<ExampleCustomInput />}
              />
              <SelectDate
                setStartDate={(val) => setStartDate(val)}
                setEndDate={(val) => setEndDate(val)}
              />
            </div> */}
                {seat?.phoneNum && tabMenuBar === 4 && (
                  <AddBtn style={{ marginBottom: '10px' }} onClick={toggleMileageModal}>
                    마일리지 수동 적립/차감
                  </AddBtn>
                )}
              </DatePickerStyle>
              {seat?.phoneNum && tabMenuBar === 0 && (
                <>
                  {/* <p className="title">
              {seat.phoneNum}회원의 구매한 이용권 목록입니다.
            </p> */}
                  <div className="datatable-container">
                    <Table
                      tableHeight={500}
                      data={dataList}
                      columns={userTicketListColumn}
                      striped={true}
                      center={true}
                    />
                  </div>
                  {totalLength > 0 && (
                    <PaginationComponent
                      setCurrentPage={setCurrentPage}
                      totalLength={totalLength}
                      postsPerPage={postsPerPage}
                      currentPage={currentPage}
                    />
                  )}
                </>
              )}

              {tabMenuBar === 1 && (
                <>
                  {/* <p className="title">{seat.phoneNum}회원의 입퇴장 내역입니다.</p> */}
                  <div className="datatable-container">
                    <Table
                      tableHeight={500}
                      data={userInOutData}
                      columns={userLogColumn}
                      striped={true}
                      center={true}
                    />
                  </div>
                  {totalLength2 > 0 && (
                    <PaginationComponent
                      setCurrentPage={setCurrentPage}
                      totalLength={totalLength2}
                      postsPerPage={postsPerPage}
                      currentPage={currentPage}
                    />
                  )}
                </>
              )}
              {tabMenuBar === 2 && (
                <>
                  {/* <p className="title">{seat.phoneNum}회원의 결제내역입니다.</p> */}
                  <div className="datatable-container">
                    <Table
                      tableHeight={500}
                      data={userPayData}
                      columns={paylogListColumns1}
                      striped={true}
                      center={true}
                    />
                  </div>
                  {totalLength3 > 0 && (
                    <PaginationComponent
                      setCurrentPage={setCurrentPage}
                      totalLength={totalLength3}
                      postsPerPage={postsPerPage}
                      currentPage={currentPage}
                    />
                  )}
                </>
              )}
              {tabMenuBar === 3 && (
                <>
                  <MemoContainer>
                    <div className="add-memo">
                      메모 작성
                      <form>
                        <textarea
                          id="textarea"
                          maxLength={40}
                          placeholder="내용을 입력해 주세요. (40자 이내)"
                          onChange={memoHandler}
                        />
                        <button onClick={submitHandler}>메모 작성</button>
                      </form>
                    </div>
                    <ul className="memo-list">
                      <div className="memo-title">작성 내역</div>
                      {memolist.length === 0 ? (
                        <div className="no-memo">등록된 메모가 없습니다.</div>
                      ) : (
                        memolist.map((memo) => (
                          <li>
                            <div className="memo">{memo.memo}</div>
                            <div className="created">
                              {/* {memo.createdAt.replace("T", " ").substring(0, 19)}{" "} */}
                              {moment(memo.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                              작성&nbsp;
                              <button onClick={() => deleteHandler(memo.id)}>삭제</button>
                            </div>
                          </li>
                        ))
                      )}
                    </ul>
                  </MemoContainer>
                </>
              )}
              {tabMenuBar === 4 && (
                <>
                  {/* <p className="title">{seat.phoneNum}회원의 결제내역입니다.</p> */}
                  <div className="datatable-container">
                    <Table
                      tableHeight={500}
                      data={mileage}
                      columns={mileageColumn2}
                      striped={true}
                      center={true}
                    />
                  </div>
                  {totalLength4 > 0 && (
                    <PaginationComponent
                      setCurrentPage={setCurrentPage}
                      totalLength={totalLength4}
                      postsPerPage={postsPerPage}
                      currentPage={currentPage}
                    />
                  )}
                </>
              )}

              <p>학부모 번호</p>
              <ParentsContainer>
                <Input
                  type="text"
                  name="parentPhoneNum"
                  value={parentPhoneNum || ''}
                  onChange={(e) => parentPhoneNumChange(e)}
                />
                <button className="btn-primary btn-sm" onClick={() => userUpdate()}>
                  저장하기
                </button>
              </ParentsContainer>
            </ModalBody>
          </>
        )}
      </Modal>
      {back && (
        <ModalBackground
          onClick={() => {
            accessChange();
          }}
        />
      )}
    </>
  );
};

export default DetailsModal;
