import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import { getMerchantInfo, merchantId_api } from '../lib/api';
import {
  innerKRestart,
  innerPRestart,
  innerUpdate,
  kRestart,
  outerUpdate,
  pRestart,
} from '../lib/socket';
import { Button } from '../pages/lockerChange';
import configDB from '../data/customizer/config';
import OpenDoorModal from '../components/modal/openDoorModal';
import PageContainer from '../components/common/PageContainer';
import useHandleAPICall from '../lib/hooks/useHandleAPICall';
import useHandleError from '../lib/hooks/useHandleError';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: 768px) {
    width: 32rem;
    flex-direction: row;
  }
`;

const Kiosk_setting = () => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [merchantId, setMerchantId] = useState(0);
  const [isRestartModal, setIsRestartModal] = useState(false);
  const [multiNum, setMultiNum] = useState(null);
  const [kind, setKind] = useState('');

  const fetch = async () => {
    try {
      const { data } = await createRequest(merchantId_api);
      setMerchantId(data.id);
      const res = await createRequest(getMerchantInfo, data.id);
      setMultiNum(res.data[0].multiKioskFloor);
    } catch (error) {
      catchError(error);
    }
  };

  const programUpdate = async (mid) => {
    Swal.fire({
      title: '키오스크 재시작',
      text: '외부 키오스크를 재시작하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: primary,
      confirmButtonText: '실행',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.isConfirmed) {
        pRestart(mid);
        Swal.fire({
          icon: 'success',
          title: '키오스크가 재시작됩니다.',
          timer: 2000,
        });
      }
    });
  };

  const inner_programUpdate = async (mid) => {
    Swal.fire({
      title: '키오스크 재시작',
      text: '내부 키오스크를 재시작하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: primary,
      confirmButtonText: '실행',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.isConfirmed) {
        innerPRestart(mid);
        Swal.fire({
          icon: 'success',
          title: '키오스크가 재시작됩니다.',
          timer: 2000,
        });
      }
    });
  };

  const pcUpdate = async (mid) => {
    Swal.fire({
      title: 'PC 재시작',
      text: '외부 PC를 재시작 하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: primary,
      confirmButtonText: '실행',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.isConfirmed) {
        kRestart(mid);
        Swal.fire({
          icon: 'success',
          title: '키오스크가 재시작됩니다.',
          timer: 2000,
        });
      }
    });
  };

  const inner_pcUpdate = async (mid) => {
    Swal.fire({
      title: 'PC 재시작',
      text: '내부 PC를 재시작 하시겠습니까?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: primary,
      confirmButtonText: '실행',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.isConfirmed) {
        innerKRestart(mid);
        Swal.fire({
          icon: 'success',
          title: '키오스크가 재시작됩니다.',
          timer: 2000,
        });
      }
    });
  };

  //층별 모달
  const openModal = (kindString) => {
    setKind(kindString);
    setIsRestartModal(true);
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Fragment>
      {isRestartModal && multiNum && (
        <OpenDoorModal
          show={isRestartModal}
          onClose={setIsRestartModal}
          data={multiNum}
          id={merchantId}
          kind={kind === 'PC' ? 'PC' : 'Program'}
        />
      )}
      <PageContainer title="키오스크 설정">
        <h5>키오스크 프로그램 재시작</h5>
        <h6>외부 키오스크</h6>
        <ButtonWrapper>
          <Button color={primary} onClick={() => programUpdate(merchantId)}>
            외부 프로그램 재시작
          </Button>
          <Button color="#fff" onClick={() => pcUpdate(merchantId)}>
            외부PC 재시작
          </Button>
        </ButtonWrapper>
        <h6>내부 키오스크</h6>
        {!multiNum && (
          <ButtonWrapper>
            <Button color={primary} onClick={() => inner_programUpdate(merchantId)}>
              내부 프로그램 재시작
            </Button>
            <Button color="#fff" onClick={() => inner_pcUpdate(merchantId)}>
              내부PC 재시작
            </Button>
          </ButtonWrapper>
        )}
        {multiNum && (
          <>
            <ButtonWrapper>
              <Button color={primary} onClick={() => inner_programUpdate(merchantId)}>
                내부 프로그램 전체 재시작
              </Button>
              <Button color="#fff" onClick={() => inner_pcUpdate(merchantId)}>
                내부PC 전체 재시작
              </Button>
            </ButtonWrapper>

            <ButtonWrapper>
              <Button color={primary} onClick={() => openModal('Program')}>
                내부 프로그램 층별 재시작
              </Button>
              <Button
                color="#fff"
                onClick={() => {
                  openModal('PC');
                }}>
                내부PC 층별 재시작
              </Button>
            </ButtonWrapper>
          </>
        )}
        <h6>키오스크 업데이트</h6>
        <ButtonWrapper>
          <Button color={primary} onClick={() => outerUpdate(merchantId)}>
            외부 키오스크 업데이트
          </Button>
          <Button color="#fff" onClick={() => innerUpdate(merchantId)}>
            내부 키오스크 업데이트
          </Button>
        </ButtonWrapper>
      </PageContainer>
      <Container fluid={true}>
        <Row>
          <Col style={{ padding: '0 6px' }}></Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default Kiosk_setting;
