import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Form, Input, Row } from 'reactstrap';

import styled from 'styled-components';

import PaginationComponent from '../../components/pagination';

import { LabelRow } from '../ticketCustom';

import { getUserData, userLog } from '../../lib/api';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { userLogColumn } from '../../data/columns';
import Breadcrumbs from '../../layout/breadcrumb';
import { getInfo } from '../../lib/api/v2/user';
import Table, { TableHeaderContainer } from '../../components/common/Table';
import PageContainer from '../../components/common/PageContainer';
import { SearchButton } from '../../components/input';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';
import useHandleError from '../../lib/hooks/useHandleError';

const CardContainer = styled.div`
  .topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vw;

    .m-0 {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      width: 100%;

      .fa-search {
        position: absolute;
        right: 12px;
        top: 16.8px;
      }
    }
  }
  .phoneNum-click {
    color: #04c9db;
    &:hover {
      border-bottom: 1px solid #04c9db;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 768px) {
    .topbar {
      flex-direction: column;
      justify-content: center;
      form {
        margin: 1vh 0;
      }
    }
  }
`;

const DataBox = styled.div`
  .pagination-container {
    width: 100%;
    margin: 1vh 0;
    display: flex;
    justify-content: center;
  }
`;

const UserInfo = () => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [startDate, setStartDate] = useState(new Date('2020-01-01'));
  const [endDate, setEndDate] = useState(new Date('2030-01-01'));
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [userInOutData, setUserInOutData] = useState([]);
  const [totalCount, setTotalcount] = useState([]);
  const getUser = async () => {
    try {
      const { data } = await createRequest(getInfo);
      const { merchantId, phoneNum } = data;
      await createRequest(getUserInfoData, merchantId, phoneNum);
    } catch (error) {
      catchError(error);
    }
  };

  const getUserInfoData = async (i, n) => {
    try {
      const userInfoData = await createRequest(
        userLog,
        i,
        moment(startDate).format('YYYYMMDD000000'),
        moment(endDate).format('YYYYMMDD235959'),
        currentPage,
        postsPerPage,
        n,
      );

      if (userInfoData.status === 200) {
        setUserInOutData(
          userInfoData.data.result,
          userInfoData.data.result.map((item, index) => {
            item.idx = item.id;
            item.id = item.id * index;
            item.create = moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss');
            item.seatNumber1 = (item.seatNumber !== null ? item.seatNumber : '-') + '번';
          }),
        );
        setTotalcount(userInfoData.data.count);
      }
    } catch (error) {
      catchError(error);
    }
  };

  useEffect(() => {
    getUser();
  }, [startDate, endDate, currentPage]);
  return (
    <>
      <Fragment>
        <PageContainer title="이용내역">
          <DataBox>
            <div className="datatable-container">
              <Table data={userInOutData} columns={userLogColumn} striped={true} center={true} />
            </div>
            {totalCount > 0 && (
              <PaginationComponent
                setCurrentPage={setCurrentPage}
                totalLength={totalCount}
                postsPerPage={postsPerPage}
                currentPage={currentPage}
              />
            )}
          </DataBox>
        </PageContainer>
      </Fragment>
    </>
  );
};
export default UserInfo;
