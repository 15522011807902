import moment from 'moment';
import React, { useState } from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { userList, userTicketEnroll } from '../../lib/api';
import { Button, Label1 } from '../../pages/lockerChange';
import { InputWithP } from '../../pages/ticketList';
import configDB from '../../data/customizer/config';
import { ModalBackground } from './choiceModal';
import { Label2 } from '../../pages/coupon/coupon';
import { Dropdown } from '../common/Dropdown';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';
import useHandleError from '../../lib/hooks/useHandleError';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const TextContainer = styled.div`
  h5 {
    font-size: 13px;
    strong {
      color: red;
    }
  }
  @media screen and (max-width: 480px) {
    margin: 1vw 0 6vw 0;
  }
`;

const InputDatas = styled.div`
  width: 80%;
  label {
    width: 50%;
    span input,
    select {
      width: 100% !important;
    }
  }
`;

const SeminarReservationModal = ({ close, startTime, endTime, mid, seminarId, seminarNum }) => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();
  const [phoneNum, setPhoneNum] = useState('');
  const [paymentType, setPaymentType] = useState('cash');
  const [amount, setAmount] = useState(1);

  const reservation = async () => {
    const startData = `${startTime.date} ${startTime.time.slice(0, 2)}:${startTime.time.slice(
      2,
      4,
    )}:00`;
    const endData = `${endTime.date} ${endTime.time.slice(0, 2)}:${endTime.time.slice(2, 4)}:00`;
    const start = moment(startData);
    const end = moment(endData);
    const diffTime = moment.duration(end.diff(start)).asSeconds();
    if (phoneNum) {
      try {
        const userData = await createRequest(userList, mid, phoneNum);
        if (userData.status === 200 && userData.data.length !== 0) {
          const res = await createRequest(userTicketEnroll, {
            merchantId: mid,
            phoneNum: phoneNum,
            startDate: startData,
            userId: userData.data[0].id,
            endDate: null,
            remainTime: diffTime,
            usedTime: 0,
            seatType: 0,
            seminarId: seminarId,
            seatNumber: seminarNum,
            type: 4,
            paymentType: paymentType,
            amount: amount,
            level: 1,
            meta8: `백오피스-세미나실 예약 결제`,
            meta13: `이용자(예약):${phoneNum}`,
          });

          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: '세미나실 구매가 완료되었습니다.',
              timer: 2000,
            }).then(() => {
              window.location.reload();
            });
          } else if (res.status === 202) {
            Swal.fire({
              icon: 'error',
              title: '중복예약입니다. \n 다른 시간대를 선택해주세요',
              timer: 3000,
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: '세미나실 예약 도중 에러가 발생했습니다. \n 관리자에게 문의해주세요.',
              timer: 3000,
            });
          }
        }
        //회원정보가 없을경우엔 해당회원정보가 없다다는 에러 확인
        else {
          Swal.fire({
            icon: 'error',
            title: '해당 회원 정보가 없습니다.',
            timer: 3000,
          });
        }
      } catch (error) {
        catchError(error);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: '유효하지 않은 휴대폰 번호 입니다.',
        timer: 2000,
      });
    }
  };
  return (
    <>
      <Modal centered isOpen={close} toggle={close}>
        <ModalHeader className="modal-title" toggle={close}>
          세미나실 예약
        </ModalHeader>
        <ModalBody>
          <TextContainer>
            <h5>예약일자</h5>
            <p>
              {startTime.date} {startTime.time.slice(0, 2)}:{startTime.time.slice(2, 4)} ~{' '}
              {endTime.date} {endTime.time.slice(0, 2)}:{endTime.time.slice(2, 4)}
            </p>
          </TextContainer>
          <InputDatas>
            <Label2>
              <h5>휴대전화 번호</h5>
              <InputWithP>
                <Input
                  type="text"
                  min="0"
                  name="phoneNum"
                  onChange={(e) => setPhoneNum(e.target.value)}
                  value={phoneNum}
                />
              </InputWithP>
            </Label2>
            <Label1>
              <h5>매출유형</h5>
              <Dropdown>
                <select
                  name="paymentType"
                  value={paymentType || ''}
                  onChange={(e) => setPaymentType(e.target.value)}
                  style={{ width: 'fit-content' }}>
                  매출종류
                  <option value="cash">현금</option>
                  {/* <option value={3}>간편결제</option> */}
                  <option value="virtual">가매입/가매출</option>
                </select>
              </Dropdown>
            </Label1>
            <Label1>
              <h5>결제금액</h5>
              <InputWithP>
                <Input
                  type="text"
                  min="0"
                  name="Amount"
                  onChange={(e) => setAmount(e.target.value)}
                  value={amount}
                />
              </InputWithP>
            </Label1>
          </InputDatas>
          <div
            style={{
              display: 'flex',
              width: '100%',
              gap: '10px',
            }}>
            <Button color="#fff" onClick={close}>
              취소
            </Button>
            <Button onClick={reservation} color={primary}>
              예약
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <ModalBackground />
    </>
  );
};

export default SeminarReservationModal;
