import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import { Card, CardBody } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { CheckBoxRound } from '../auth/signup';

import { getUUID, HectoPay, numberWithCommas, tossPayMessage } from '../components/common';
import {
  getMerchantInfo,
  getMsgItemList,
  getUserData,
  merchantId_api,
  msgPointWebpay,
} from '../lib/api';
import SelectPaymentType from '../components/modal/selectPaymentType';
import PageContainer from '../components/common/PageContainer';
import useHandleAPICall from '../lib/hooks/useHandleAPICall';
import useHandleError from '../lib/hooks/useHandleError';

const CardContainer = styled.div`
  .boxContainer {
    /* width: 80%; */
    /* margin: 1vw auto; */
    /* border: 1px solid #e1e1e1; */
    /* padding: 3vw; */
    /* border-radius: 5px; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .possessionData {
      width: 100%;
      background: rgba(225, 225, 225, 0.52);
      border-radius: 4px;
      p {
        font-weight: 700;
        text-align: center;
        padding: 1vw;
      }
    }
    p {
      font-size: 16px;
    }
    .defaultPoint {
      padding: 1rem;
      text-align: center;
      font-weight: bold;
      font-size: 1.25rem;
    }
    .pointChargeTable {
      width: 100%;
      border: 1px solid #e1e1e1;
      tr {
        border-bottom: 1px solid #e1e1e1;
      }

      th {
        font-size: 13px;
        color: #898a8d;
        font-weight: 400;
      }

      th,
      td {
        text-align: center;
        padding: 1rem;

        &:nth-child(1) {
          padding: 1vw 0 1vw 5vw;
          width: 12rem;
        }
      }
      .point {
        font-weight: 400;
      }
      .price {
        color: #898a8d;
      }
      .active {
        .point {
          font-weight: 700;
        }
      }
    }
    .paymentPrice {
      display: flex;
      gap: 2rem;
      flex-direction: row;
      /* flex-direction: row; */
      /* justify-content: space-between; */
      div:last-child {
        font-weight: bold;
      }
    }
    .bold {
      font-weight: 700;
    }
  }

  .submit {
    display: block;
    margin: 2vw auto 0;
  }
  .title {
    color: #222222;
    font-size: 1rem;
    font-weight: 400;
  }

  @media screen and (max-width: 768px) {
    .title {
      font-size: 1rem;
    }
  }
`;

const MessageCharge = () => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [choicePoint, setChoicePoint] = useState(0);

  const [merchantDefault, setMerchantDefault] = useState({});
  const [chergeItemData, setChergeItemData] = useState([]);
  const [clientKey, setclientKey] = useState([]);
  const [isSelectPaymentModal, setIsSelectPaymentModal] = useState(false);
  const [payData, setPayData] = useState({ id: '', points: '' });
  const [merchantId, setMerchantId] = useState();
  const getDefaultData = async () => {
    try {
      const { data } = await createRequest(merchantId_api);
      const itemData = await createRequest(getMsgItemList);
      setMerchantId(data.id);
      const res = await createRequest(getMerchantInfo, data.id);
      setclientKey(itemData.data.key);
      setMerchantDefault(res.data[0]);
      setChergeItemData(itemData.data);
    } catch (error) {
      catchError(error);
    }
  };

  // checkbox Event
  const changeBtn = (index) => {
    setChoicePoint(+index);
  };

  // payment Func
  const buyMessagePoint = () => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: '#04c9db',
      cancelButtonColor: '#F53C14',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      reverseButtons: true,
      html: ` 충전 포인트: <span style="font-weight:800; font-size:20px">${numberWithCommas(
        chergeItemData[choicePoint].points,
      )}</span>p
      <br> 결제 금액: <span style="font-weight:800; font-size:20px">${numberWithCommas(
        chergeItemData[choicePoint].amount,
      )}</span>원 <br> 맞으실 경우 확인을 눌러주세요. `,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsSelectPaymentModal(true);
        setPayData({
          id: chergeItemData[choicePoint].id,
          points: chergeItemData[choicePoint].points,
          mid: merchantId,
          title: '구매 정보 확인',
          amount: chergeItemData[choicePoint].amount,
          //결제 수단 판별을 위한 type
          type: 'message',
        });
      }
    });
  };
  useEffect(() => {
    getDefaultData();
  }, []);
  return (
    <>
      {isSelectPaymentModal && (
        <SelectPaymentType
          show={isSelectPaymentModal}
          onClose={() => setIsSelectPaymentModal(false)}
          data={merchantDefault}
          payData={payData}
        />
      )}
      <Fragment>
        <PageContainer title="메시지 포인트 충전">
          <CardContainer>
            <div className="boxContainer">
              <span className="title">보유 포인트</span>
              <div className="possessionData">
                <div className="defaultPoint">
                  {numberWithCommas(
                    merchantDefault?.maxSendMessage - merchantDefault?.sendMessageUseCount,
                  )}
                </div>
              </div>
              <span className="title">충전할 포인트</span>
              <div>
                <table className="pointChargeTable">
                  <tr>
                    <th className="choiceData"></th>
                    <th>충전 포인트</th>
                    <th>결제 금액</th>
                  </tr>
                  {chergeItemData &&
                    chergeItemData.map((item, index) => (
                      <tr key={`index${index}`} className={choicePoint === index ? 'active' : ''}>
                        <td>
                          <CheckBoxRound>
                            <div style={{ display: 'flex' }}>
                              <input
                                type="checkbox"
                                name="smsDeny"
                                onChange={() => changeBtn(index)}
                                value={index}
                                checked={choicePoint === index}
                              />
                              <span className="checkbox_icon" />
                            </div>
                          </CheckBoxRound>
                        </td>
                        <td className="point">
                          <span>{numberWithCommas(item?.points)}p</span>
                        </td>
                        <td className="price">
                          <span>{numberWithCommas(item?.amount)}원</span>
                        </td>
                      </tr>
                    ))}
                </table>
              </div>
              <div className="paymentPrice">
                <div className="title">결제금액</div>
                <div className="title">
                  {numberWithCommas(chergeItemData[choicePoint]?.amount) || 0} 원
                </div>
              </div>
            </div>

            <button className="btn btn-primary submit" onClick={buyMessagePoint}>
              결제하기
            </button>
          </CardContainer>
        </PageContainer>
      </Fragment>
    </>
  );
};

export default MessageCharge;
