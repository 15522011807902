import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Format from '../../util/formatter';
import { getSaleData } from '../../lib/api';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';
import useHandleError from '../../lib/hooks/useHandleError';

const FooterContainer = styled.div<{ isMobile?: boolean }>`
  width: 100%;

  position: relative;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;

  background: white;

  color: ${({ theme }) => theme.neutralGray[400]};
  font-size: 0.75rem;
  line-height: 150%;

  padding: 2.5rem;
  margin-left: 14rem;
  @media screen and (max-width: 1279px) {
    padding: 1.25rem;
    margin: 0;
  }

  .top {
    font-weight: 700;
  }

  .middle {
    line-height: 150%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.75rem 0.5rem;

    /* :last-child {
      border-right: none;
    } */
  }

  .bottom {
    font-weight: 700;
  }
`;

const FooterContent = styled.div`
  display: flex;
  line-height: 100%;
  white-space: nowrap;
`;

const Divider = styled.div`
  height: 0.75rem;
  width: 1px;
  background: ${({ theme }) => theme.neutralGray[400]};
`;

const FooterItem = ({
  name,
  value,
  withoutDivider,
}: {
  name: string;
  value: string;
  withoutDivider?: boolean;
}) => {
  return (
    <>
      {value && (
        <>
          <FooterContent>
            {name} : {value}
          </FooterContent>
          {!withoutDivider && <Divider />}
        </>
      )}
    </>
  );
};

const FooterV2 = (props: any) => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [merchantData, setMerchantData] = useState({
    user_name: '',
    store_address: '',
    contact: '',
    br_number: '',
    mail_order_sales_number: '',
  });
  const { user_name, store_address, contact, br_number, mail_order_sales_number } = merchantData;
  const level = localStorage.getItem('level');
  const getMerchantInfo = async () => {
    try {
      if (level !== 'user') return;
      const res = await createRequest(getSaleData, props.data.id);
      setMerchantData(res.data[0]);
    } catch (error) {
      catchError(error);
    }
  };

  useEffect(() => {
    if (props?.data?.id) getMerchantInfo();
  }, [props?.data?.id]);
  return (
    <>
      {merchantData && (
        <FooterContainer isMobile={props?.isMobile}>
          <div className="top">상호명: 샘랩 주식회사</div>
          <div className="middle">
            <FooterItem name="대표이사" value={level === 'user' ? user_name : '심세용'} />
            <FooterItem
              name="사업자 등록번호"
              value={br_number ? Format.formatRegistrationNumber(br_number) : ''}
            />
            <FooterItem
              name="통신판매업 신고번호"
              value={level === 'user' ? mail_order_sales_number : '2019-경기안산-0776'}
            />
            <FooterItem
              name="주소"
              value={
                level === 'user'
                  ? store_address
                  : '경기도 시흥시 은계중앙로 362 수림프라자 902호 ,1001호'
              }
            />
            <FooterItem
              name="문의"
              value={Format.formatPhone(level === 'user' ? contact : '01066594264')}
              withoutDivider
            />
          </div>
          <div className="bottom">
            ©️ 2022-{moment().format('YYYY')} SAMLAB Corp. All rights reserved.
          </div>
        </FooterContainer>
      )}
    </>
  );
};

export default FooterV2;
