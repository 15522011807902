import React, { useContext } from 'react';

import { ModalDispatchContext, ModalsStateContext } from '../../store/modalContext';
import wait from 'waait';

/**
 * 각 페이지에서 공용 모달을 여닫기 위한 커스텀 훅
 * @returns 각 공용 함수
 * openModal: 커스텀 모달, openDialog: 대화 상자, openAlert: 알림(확인 전용)
 * closeModal: 모달 닫기 (modal provider 전용), closeLatestModal: 가장 최근에 열린 모달 닫기
 * TODO: openDialog 첫번째 모달을 연 후 onClickConfirm 함수 호출로 두번째 모달이 열리면 첫번째 모달과 두번째 모달이 같이 닫히는 현상
 */
const useModal = () => {
  const { open, close, reset } = useContext(ModalDispatchContext);
  const openedModals = useContext(ModalsStateContext);
  const openModal = (Component: () => JSX.Element, props: ModalProps) => {
    open(Component, props);
  };

  const openAlert = (message: string, action?: () => void) => {
    const currentComponent = () => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          {message.split('\n').map((item, idx) => {
            return (
              <div style={{ fontSize: '1rem', fontWeight: 400 }} key={idx}>
                {item}
              </div>
            );
          })}
        </div>
      );
    };
    open(currentComponent, {
      hasCancelButton: false,
      onClickConfirm: () => {
        action && action();
        closeModal(currentComponent);
      },
      confirmButtonText: '확인',
    });
  };

  const openDialog = (
    message: string,
    callbackCancel: () => Promise<any> | void,
    callbackConfirm: () => Promise<any> | void,
    confirmButtonText?: string,
    cancelButtonText?: string,
    image?: string,
  ) => {
    const currentComponent = () => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          {image && <img src={image} alt="modal-image" style={{ paddingBottom: '2rem' }} />}
          {message.split('\n').map((item, idx) => {
            return (
              <div style={{ fontSize: '1rem', fontWeight: 400 }} key={idx}>
                {item}
              </div>
            );
          })}
        </div>
      );
    };
    open(currentComponent, {
      hasCancelButton: true,
      onClickConfirm: async () => {
        closeModal(currentComponent);
        await wait(100);
        await callbackConfirm();
      },
      onClickCancel: async () => {
        closeModal(currentComponent);
        await wait(100);
        callbackCancel && (await callbackCancel());
      },
      confirmButtonText: confirmButtonText || '확인',
      cancelButtonText: cancelButtonText || '취소',
    });
  };

  const closeModal = (Component: () => JSX.Element) => {
    close(Component);
  };

  const closeAllModal = () => {
    reset();
  };

  /**
   * 가장 최근에 열린 모달을 닫음
   * 기존 버튼을 사용하지 않고 커스텀 버튼을 활성화할 때 사용
   */
  const closeLatestModal = () => {
    if (openedModals.length < 1) return;
    close(openedModals[openedModals.length - 1].Component);
  };

  /**
   * 전체 모달 닫기
   */
  const closeAll = () => {
    reset();
  };

  return {
    openModal,
    openDialog,
    openAlert,
    closeModal,
    closeLatestModal,
    closeAllModal,
    closeAll,
  };
};

export default useModal;
