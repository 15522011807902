import React, { useState } from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button, Label1 } from '../../pages/lockerChange';
import { ModalBackground } from './choiceModal';
import configDB from '../../data/customizer/config';
import { postMileageAdd } from '../../lib/api';
import Swal from 'sweetalert2';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';
import useHandleError from '../../lib/hooks/useHandleError';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const MileageAddModal = ({ isOpen, onClose, user, userId, reset }) => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [amount, setAmount] = useState('');
  const handleMileageAddApi = async () => {
    if (+amount !== 0 && +amount < 1000000) {
      try {
        const res = await createRequest(postMileageAdd, {
          amount: +amount,
          userId: userId,
          merchantId: user.merchantId,
          transactionId: null,
          msgCheck: 1,
        });
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            timer: 2000,
            title: `마일리지 ${amount > 0 ? '적립' : '차감'}이 완료됐습니다.`,
          }).then(() => {
            onClose();
            reset();
          });
        }
      } catch (err) {
        catchError(err);
      }
    } else if (+amount > 1000000) {
      Swal.fire({
        icon: 'error',
        timer: 2000,
        title: '한 번에 최대 적립 / 차감할 수 있는 포인트는 1,000,000포인트입니다.',
      });
    } else {
      Swal.fire({
        icon: 'error',
        timer: 2000,
        title: '적립/차감할 마일리지를 입력해주세요.',
      });
    }
  };

  const setMileageValue = (value) => {
    const onlyNum = /^[+-]?\d*$/;
    if (onlyNum.test(value)) setAmount(value);
  };

  return (
    <>
      <Modal centered isOpen={isOpen} toggle={onClose}>
        <ModalHeader className="modal-title" toggle={onClose}>
          마일리지 수동 적립/차감
        </ModalHeader>
        <ModalBody>
          <Label1>
            <h5>적립(-차감)</h5>
            <Input
              className="form-control"
              type="text"
              name="amount"
              value={amount || ''}
              onChange={(e) => setMileageValue(e.target.value)}
            />
          </Label1>

          <div
            style={{
              display: 'flex',
              width: '100%',
              gap: '10px',
            }}>
            <Button color="#fff" onClick={onClose}>
              취소
            </Button>
            <Button onClick={handleMileageAddApi} color={primary}>
              적용
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <ModalBackground />
    </>
  );
};

export default MileageAddModal;
