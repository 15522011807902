import moment from 'moment';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { getPayAllList, merchantId_api } from '../lib/api';
import PageContainer from '../components/common/PageContainer';
import useHandleAPICall from '../lib/hooks/useHandleAPICall';
import useHandleError from '../lib/hooks/useHandleError';
import Format from '../util/formatter';

const CalenderStyle = styled.div`
  .choiceMonth {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      font-size: 20px;
      margin: 0;
      cursor: pointer;
    }
    h5 {
      margin: 0 2vw;
    }
  }
  .calender {
    width: 80%;
    margin: 0 auto;
    .dateHeader div {
      /* border-bottom: 2px solid #4f4f4f; */
      padding: 10px;
    }

    .dateBox,
    .noColor {
      border: 1px solid rgba(174, 174, 174, 0.5);
      /* border-radius: 4px; */
      padding: 10px;
      height: 8vw;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    /* grid-gap: 5px; */
    p {
      margin: 0;
    }

    .date {
      font-weight: bold;
    }
  }
  .dateBoard {
    border: 1px solid rgba(174, 174, 174, 0.5);
    div:nth-child(7n + 0) {
      p:nth-child(1) {
        color: blue;
      }
    }
    div:nth-child(7n + 1) {
      p:nth-child(1) {
        color: red;
      }
    }
  }
  p.cancelData {
    color: red;
  }

  @media screen and (max-width: 480px) {
    .calender {
      width: 100%;
      overflow: scroll;
      .dateHeader div,
      .dateBoard div {
        width: 100px;
      }

      .dateBoard {
        width: 700px;
        div {
          height: 100px;
        }
      }
    }
  }
`;

const SalesDay = () => {
  const { createRequest } = useHandleAPICall();
  const { catchError } = useHandleError();

  const [searchDate, setSearchDate] = useState(new Date());
  const [calenderMonth, setCalenderMonth] = useState(null);
  const [calenderYear, setCalenderYear] = useState(null);

  //date
  const date = ['일', '월', '화', '수', '목', '금', '토'];

  let htmlDummy = '';
  // 날짜 구하기
  const makerDate = (date, logDatas) => {
    const nowYear = new Date(date).getFullYear();
    const nowMonth = new Date(date).getMonth() + 1;

    const prevDay = new Date(nowYear, nowMonth - 1, 1).getDay();
    const lastDay = new Date(nowYear, nowMonth, 0).getDate();

    // 남은 박스만큼 다음달 날짜 표시
    const limitDay = prevDay + lastDay;
    const nextDay = Math.ceil(limitDay / 7) * 7;

    for (let i = 0; i < prevDay; i++) {
      htmlDummy += `<div class="noColor"></div>`;
    }

    for (let i = 1; i <= lastDay; i++) {
      htmlDummy += `<div class="dateBox"><p class="date">${i}</p><p>+${
        logDatas[i - 1].sales !== 0 ? `${Format.formatNumber(logDatas[i - 1].sales)}원` : 0
      }</p><p class="cancelData">-${
        logDatas[i - 1].cancel !== 0 ? `${Format.formatNumber(logDatas[i - 1].cancel)}원` : 0
      }</p></div>`;
    }

    for (let i = limitDay; i < nextDay; i++) {
      htmlDummy += `<div class="noColor"></div>`;
    }

    document.querySelector(`.dateBoard`).innerHTML = htmlDummy;
    setCalenderMonth(nowMonth);
    setCalenderYear(nowYear);
  };

  // 결제로그 구하기
  const getPaylog = async () => {
    try {
      const { data } = await createRequest(merchantId_api);
      const nowYear = new Date(searchDate).getFullYear();
      const nowMonth = new Date(searchDate).getMonth() + 1;
      const prevDay = new Date(nowYear, nowMonth - 1, 1);
      const lastDay = new Date(nowYear, nowMonth, 0);

      const startDate = moment(prevDay).format('YYYY-MM-DD 00:00:00');
      const endDate = moment(lastDay).format('YYYY-MM-DD 23:59:59');
      const paylog = await createRequest(getPayAllList, data.id, startDate, endDate);

      //1. 날짜 형식 변경
      let payLogData = [
        paylog.data,
        paylog.data.map((item) => (item.payDate = moment(item.createdAt).format('DD'))),
      ];
      //2. 일 별로 쪼개기.
      let dayArray = Array(lastDay.getDate())
        .fill()
        .map((v, i) => ({ sales: 0, cancel: 0 }));
      for (let j = 0; j < dayArray.length; j++) {
        for (let i = 0; i < payLogData[0].length; i++) {
          if (+payLogData[0][i].payDate === j + 1) {
            if (
              //환불한것 (cancel일 경우)
              payLogData[0][i].paymentType &&
              payLogData[0][i].paymentType !== 'mileage' &&
              payLogData[0][i].paymentType.includes('cancel') &&
              +payLogData[0][i].paymentType !== 'coupon'
            ) {
              dayArray[j].cancel += payLogData[0][i].amount;
            } else if (
              // 실제 매출
              payLogData[0][i].paymentType &&
              payLogData[0][i].paymentType !== 'mileage' &&
              !payLogData[0][i].paymentType.includes('cancel') &&
              +payLogData[0][i].paymentType !== 'coupon'
            ) {
              dayArray[j].sales += payLogData[0][i].amount;
            }
          }
        }
      }
      makerDate(searchDate, dayArray);
    } catch (error) {
      catchError(error);
    }
  };

  // 월 변경
  const changeDate = (type) => {
    if (type === 'prev') {
      setSearchDate(new Date(searchDate.setMonth(searchDate.getMonth() - 1)));
    } else if (type === 'next') {
      setSearchDate(new Date(searchDate.setMonth(searchDate.getMonth() + 1)));
    }
  };

  useEffect(() => {
    getPaylog();
  }, [searchDate]);

  return (
    <CalenderStyle>
      <Fragment>
        <PageContainer title="일 별 매출 보기">
          <div className="choiceMonth">
            <p onClick={() => changeDate('prev')}>&lt;</p>
            <h5>
              {calenderYear}년 {calenderMonth}월
            </h5>
            <p onClick={() => changeDate('next')}>&gt;</p>
          </div>
          <div className="calender">
            <div className="grid dateHeader">
              {date.map((item, index) => (
                <div key={'it' + index}>{item}</div>
              ))}
            </div>
            <div className="grid dateBoard"></div>
          </div>
        </PageContainer>
      </Fragment>
    </CalenderStyle>
  );
};

export default SalesDay;
