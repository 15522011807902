import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';

import { SeatArrayS, SeatView, TabButton, Text, Wall } from '../../pages/seatList';

import { getMerchantInfo } from '../../lib/api';
import CommonWrapper from './CommonWrapper';
import { getSeatList, getSeatType } from '../../lib/api/v2/seat';
import { checkIfCurrentFloor, swalReturnWarning } from '../../util/commonFunctions';
import useModal from '../../lib/hooks/useModal';
import { MapViewSeat, SeatLevel, SeatV2 } from '../common/Seat';
import useWindow from '../../lib/hooks/useWindow';
import { Maximize2, Minimize2 } from 'react-feather';
import useHandleAPICall from '../../lib/hooks/useHandleAPICall';
import useHandleError from '../../lib/hooks/useHandleError';

const Container = styled.div`
  position: relative;
  width: 60rem;
  max-width: 100%;
  overflow: scroll;
  height: 75vh;

  ${({ isMinimal }) =>
    !isMinimal &&
    css`
      padding: 0 1rem;
    `}
`;

export const SelectBox = styled.div`
  width: 100%;
  position: relative;
  select option {
    padding: 4vw 1vw;
  }
  img {
    width: 1vw;
    position: absolute;
    transform: rotate(-90deg);
    top: 44%;
    right: 3.8vw;
  }
`;

const SizeBtn = styled.button`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  position: fixed;
  right: 1rem;
  bottom: 8rem;
  z-index: 999;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.main[600]};
  border: none;
  width: 4rem;
  height: 4rem;
  align-items: center;
  justify-content: center;
  > svg {
    color: #ffffff;
  }
  @media screen and (min-width: 805px) {
    display: none;
  }
`;

const SeatMapviewModal = ({
  onClose,
  show,
  merchantId,
  ticketItemId,
  selected,
  setChoiceSeatId,
  setChoiceSeatNum,
}) => {
  const { createRequest } = useHandleAPICall();
  const windowSize = useWindow();
  const { closeAllModal } = useModal();

  const [ratio, setRatio] = useState(1);
  const [isMinimal, setIsMinimal] = useState(true);

  const [lists, setLists] = useState([]);
  const [walls, setWalls] = useState([]);
  const [texts, setTexts] = useState([]);
  const [defaults, setDefaults] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [floorNum, setFloorNum] = useState(null);
  const [multiNum, setMultiNum] = useState(null);

  const CurrentFloorSeat = useMemo(() => {
    if (!multiNum) return lists;
    return lists.filter((item) => checkIfCurrentFloor(item, floorNum));
  }, [lists, multiNum, floorNum]);

  const CurrentFloorWall = useMemo(() => {
    if (!multiNum) return walls;
    return walls.filter((item) => checkIfCurrentFloor(item, floorNum));
  }, [walls, multiNum, floorNum]);

  const CurrentFloorText = useMemo(() => {
    if (!multiNum) return texts;
    return texts.filter((item) => checkIfCurrentFloor(item, floorNum));
  }, [texts, multiNum, floorNum]);

  const getSeatListApi = async () => {
    try {
      const res = await createRequest(getSeatList, ticketItemId);
      const { data } = await createRequest(getMerchantInfo, merchantId);

      if (res.status === 200) {
        const { etc, seat } = res.data;
        if (!seat || seat.length === 0) {
          swalReturnWarning('해당 이용권으로 사용할 수 있는 좌석이 없습니다.');
          return closeAllModal();
        }
        if (data[0].multiKioskFloor) {
          setFloorNum(1);
          setMultiNum(data[0].multiKioskFloor);
          setDefaults([...seat, ...etc]);
          setLists(seat);
          setWalls(etc.filter((li) => li.type === 'wall'));
          setTexts(etc.filter((li) => li.type === 'text'));
        } else {
          setLists(seat);
          setWalls(etc.filter((li) => li.type === 'wall'));
          setTexts(etc.filter((li) => li.type === 'text'));
        }
      }
    } catch (error) {
      swalReturnWarning('좌석 목록을 가져올 수 없습니다.\n잠시 후 다시 시도해 주세요.');
      closeAllModal();
    }
  };
  useEffect(() => {
    if (!ticketItemId) return;
    getSeatListApi();
  }, [ticketItemId]);

  const handleSelectSeat = (seat) => {
    setChoiceSeatId(seat.id);
    setChoiceSeatNum(seat.number);
    onClose();
  };

  // const changefloor = (floorData) => {
  //   if (floorNum) {
  //     setLists(
  //       defaults.filter(
  //         (item) =>
  //           item.type === 'seat' &&
  //           selected?.T_level >= item?.level &&
  //           item.seatType === 0 &&
  //           item.floor === floorData,
  //       ),
  //     );
  //     setWalls(defaults.filter((li) => li.type === 'wall' && li.floor === floorData));
  //     setTexts(defaults.filter((li) => li.type === 'text' && li.floor === floorData));
  //   }
  // };

  // useEffect(() => {
  //   // if (floorNum !== null) {
  //   //   changefloor(floorNum);
  //   // }
  // }, [floorNum]);
  useEffect(() => {
    if (!windowSize) return;
    setRatio((windowSize.width * 0.9) / 920);
    if (windowSize.width > 804) setIsMinimal(false);
  }, [windowSize]);

  return (
    // <Modal
    //   centered
    //   isOpen={show}
    //   toggle={handleClose}
    //   style={{ maxWidth: isFold ? '90vw' : '70vw', margin: '2vw auto' }}>
    //   <ModalHeader className="modal-title" toggle={handleClose}>
    //     좌석 선택
    //   </ModalHeader>
    <CommonWrapper title="좌석 선택" contentStyle={{ padding: 0 }}>
      <Container isMinimal={isMinimal}>
        {multiNum && (
          <div className="floorBtn">
            {[...Array(multiNum)].map((item, index) => (
              <TabButton
                selected={floorNum === index + 1}
                value={index + 1}
                onClick={() => setFloorNum(index + 1)}>
                {index + 1}층
              </TabButton>
            ))}
          </div>
        )}
        <SizeBtn show={true} onClick={() => setIsMinimal(!isMinimal)}>
          {!isMinimal ? <Minimize2 /> : <Maximize2 />}
        </SizeBtn>
        <SeatView ratio={ratio} isMin={isMinimal}>
          <SeatArrayS ratio={ratio} isMin={isMinimal}>
            {CurrentFloorSeat?.map((seat) => (
              <React.Fragment key={seat.id}>
                <MapViewSeat
                  ratio={ratio}
                  isMin={isMinimal}
                  used="#222"
                  top={seat.pos_top}
                  left={seat.pos_left}
                  onClick={() => {
                    if (seat.status !== 'idle') return;
                    handleSelectSeat && handleSelectSeat(seat);

                    // if (seat.status === 'block' || seat.status === 1) return blockModal(seat);
                    // if (seat.status === 'idle') return blockModal(seat);
                    // showModal(seat);
                    // blockModal(seat);
                  }}
                  {...seat}>
                  <SeatLevel ratio={ratio} isMinimalized={isMinimal} level={seat.level} inverted />
                  <div className="seatNumber">{seat.number}</div>
                </MapViewSeat>

                {/* {(seat.status === 'block' || seat.status === 1) && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    used="#979797"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )}
                {seat.status === 'idle' && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    className="seat"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    onClick={() => handleSelectSeat(seat)}
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )}
                {seat.status === 'using' && seat.seatType === 1 && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    className="seat"
                    used="#1BBABC"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )}
                {seat.status === 'using' && seat.seatType === 0 && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    className="seat"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    used={
                      seat.ticketType === 1
                        ? '#616161'
                        : seat.ticketType === 2
                        ? '#FF8F00'
                        : '#00838F'
                    }
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )}
                {seat.status === 'rest' && seat.seatType === 1 && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    className="seat"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    used="#7bb4ff"
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )}
                {seat.status === 'rest' && seat.seatType === 0 && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    className="seat"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    used={
                      seat.ticketType === 1
                        ? '#B0BEC5'
                        : seat.ticketType === 2
                        ? '#FFAB91'
                        : '#80DEEA'
                    }
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )}
                {seat.status === 'using' && seat.type === 'seminar' && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    used="#616161"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )} */}
              </React.Fragment>
            ))}
            {CurrentFloorWall?.map((wall) => (
              <React.Fragment key={wall.id}>
                <Wall
                  ratio={ratio}
                  isMin={isMinimal}
                  width={wall.pos_width}
                  height={wall.pos_height}
                  top={wall.pos_top}
                  left={wall.pos_left}
                />
              </React.Fragment>
            ))}
            {CurrentFloorText?.map((text) => (
              <React.Fragment key={text.id}>
                <Text
                  ratio={ratio}
                  isMin={isMinimal}
                  size={text.font_size}
                  top={text.pos_top}
                  left={text.pos_left}>
                  {text?.name}
                </Text>
              </React.Fragment>
            ))}
          </SeatArrayS>
        </SeatView>
      </Container>
    </CommonWrapper>
    // </Modal>
  );
};
export default SeatMapviewModal;
